import React from 'react'
import styled from 'styled-components'

export const LoaderAnimation = (props) => (
    <Section show={props.show} className="d-flex justify-content-center align-items-center">
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </Section>
);

export default LoaderAnimation

const Section = styled.section`
    position: fixed;
    display: ${props => props.show ? "d-flex" : "none"} !important;
    z-index: 99;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;

    &::before{

        content:'';
        display: block;
        position: absolute;
        height: 100vh;
        width: 100%;
        background: #FFFFFF;
        opacity: 0.7;
        top: 0;
        left: 0;
    }

    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    .lds-ellipsis div {
        position: absolute;
        top: 27px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: solid 2px #57D9A3;
        background: transparent;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
        left: 6px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
        left: 6px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 26px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 45px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
        }
        @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
        }
        @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(19px, 0);
        }
    }

`
