export const mountSelectedCarObject = (contracts) => {
  if (!contracts) return []
  return contracts
    .map(contract => ({
        ...contract.vehicle,
        contractInfo: contract,
    }))
    .map((vehicle, key) => ({
        contractInfo: vehicle.contractInfo,
        label: vehicle.vModel ? `${vehicle.numberPlate} - ${vehicle.vModel}`.toUpperCase() : vehicle.numberPlate ? vehicle.numberPlate.toUpperCase() : `Dispositivo ${key + 1}`,
        value: `${key}-${vehicle.numberPlate}`,
      }));
}
