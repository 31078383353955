// Dependencias

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useForm from 'react-hook-form';
import Lockr from 'lockr';
import * as masks from '../../helpers/masks'

// Componentes

import { Container, Row, Col } from 'react-bootstrap'
import Header from '../Header'
import Body from '../Body'
import SapService from '../../services/SapService';
import LoaderAnimation from '../LoaderAnimation';
import CpfCnpjService from '../../services/CpfCnpjService';
import CorreiosService from '../../services/CorreiosService';
import geoData from '../../assets/data/estados-cidades.json';
import { URLS } from '../../helpers/Request';

let cidades = {}
let estados = geoData.estados.map((estado) => {
    cidades = estado.cidades.map((cidade) => ({ value: cidade, label: cidade }))
    return { value: estado.sigla, label: estado.nome, cidades: cidades }
})

const Form = (props) => {
    const { values } = props;
    const { register, handleSubmit, errors, clearError, setValue, setError } = useForm();
    const [type, setType] = useState(values.customerCNPJ && values.customerCNPJ.replace(/[.\-/]/g, '').length > 11 ? 'CNPJ' : 'CPF');
    const [isLoading, setIsLoading] = useState(false);
    const [isIsetoIE, setIsIsetoIE] = useState(false);

    const disableFieldsByCNPJ = (type === 'CNPJ') ? true : false
    const [disableFieldsByCEP, setBlockedByCEP] = useState(false);
    const [disableFieldsByCEPShipping, setBlockedByCEPShipping] = useState(false);
    const [hasIe, setHasIe] = useState(false);
    const [hasAddressShipping, setHasAddressShipping] = useState(false);

    useEffect(() => {
        if (values.customerCNPJ && values.customerCNPJ.replace(/[.\-/]/g, '').length > 11) setType('CNPJ');
        if (!!values.customerShippingCEP && hasAddressShipping === false) setHasAddressShipping(true);
    }, [hasAddressShipping, values.customerCNPJ, values.customerShippingCEP])

    let userData = Lockr.get('loovi-metrics');

    const toggleIsentoIE = () => {
        const status = !isIsetoIE

        if (status) setValue('ie', 'ISENTO')
        else  setValue('ie', '')

        setIsIsetoIE(status)
    }

    function fireWebToLead(data, tag) {
        let form;

        if (process.env.REACT_APP_BASE_ENVIRONMENT === 'production') {
            form = {
              'oid': '00D4P0000010vdU',
              'retURL': 'https://loovi.com.br/',

              'first_name': data.customerName.split(' ')[0],
              'last_name': data.customerName.split(' ').slice(1).join(' '),
              'email': data.customerEmail,
              'phone': data.customerPhoneNumber,
              'lead_source': 'Site Forms',

              '00N4P00000DLrb0': 'Site Forms',
              '00N6f00000FeUFt': '',
              '00N4P00000FIE0w': data.customerCEP,
              '00N4P00000FIDzZ': data.customerStreet,
              '00N4P00000FIDzo': data.customerStreetNum,
              '00N6f00000FeUFx': data.customerAdressComplement,
              '00N6f00000FeUFv': data.customerIbgeCode === undefined ? '' : data.customerIbgeCode,
              '00N4P00000FIE0X': data.customerNeighborhood,
              '00N4P00000FIE0m': data.customerUFCheck,
              '00N4P00000FIE0c': data.customerCityCheck,

              '00N6f00000FeUFs': data.customerCPForCNPJ.length === 11 ? data.customerCPForCNPJ : '',
              '00N6f00000FeUFr': data.customerCPForCNPJ.length > 11 ? data.customerCPForCNPJ : '',
              '00N6f00000FeUG3': '',
              '00N6f00000FeUG2': data.customerCPForCNPJ.length > 11  ? data.ie : '',

              '00N6f00000FeUGF': tag,
              'submit': 'Submit',
            }
          } else {
            form = {
                'oid': '00DS0000003A4nP',
                'first_name': data.customerName.split(' ')[0],
                'last_name': data.customerName.split(' ').slice(1).join(' '),
                'email': data.customerEmail,
                'phone': data.customerPhoneNumber,
                'lead_source': 'Site Forms',
                '00N4P00000DLrb0': 'Site Forms',
                '00N6f00000FeUFt': '',
                '00N4P00000FIE0w': data.customerCEP,
                '00N4P00000FIDzZ': data.customerStreet,
                '00N4P00000FIDzo': data.customerStreetNum,
                '00NS0000009IiYT': data.customerAdressComplement,
                '00NS0000009Xg6T': data.customerIbgeCode === undefined ? '' : data.customerIbgeCode,
                '00N4P00000FIE0X': data.customerNeighborhood,
                '00N4P00000FIE0m': data.customerUFCheck,
                '00N4P00000FIE0c': data.customerCityCheck,
                '00NS0000009IiYP': data.customerCPForCNPJ.length === 11 ? data.customerCPForCNPJ : '',
                '00NS0000009IiYO': data.customerCPForCNPJ.length > 11 ? data.customerCPForCNPJ : '',
                '00NS0000009X4q1': '',
                '00NS0000009X4q0': data.customerCPForCNPJ.length > 11  ? data.ie : '',
                '00N4P00000FIE11': tag,
                'submit': 'Submit',
            }
        }

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');

        fetch(`${URLS.WEBTOLEAD}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(form)
        })
            .then((res) => {
                console.log('WEB TO LEAD ENVIADO COM SUCESSO!');
            })
            .catch((err) => {
                console.log('ERRO AO ENVIAR WEB TO LEAD.');
                console.log(err);
            })
    }

    const onSubmit = async (data) => {
        setIsLoading(true);

        if(errors.customerCNPJ){
            setIsLoading(false);
            alert("Preencha com um CPF ou CNPJ válido!");

            setError('customerCNPJ', 'invalid', 'Preencha com um CPF ou CNPJ válido!')
            return false;
        }

        userData = {
            ...userData,
            ...data,
            customerCEP: data.customerCEP.replace('-', ''),
            hasIe: hasIe,
            customerShippingCEP: data.customerShippingCEP ? data.customerShippingCEP.replace('-', '') : '',
        };


        try {
            const sapData = Lockr.get('Loovi-SAP');

            if (sapData){
                if (userData.customerCNPJ.replace(/[.\-/]/g, '') === sapData.quotation.quotationFormBusinessPartner.cnpj){
                    if (sapData.quotation.quotationFormBusinessPartner.cardName !== userData.customerName){
                        userData.customerName = sapData.quotation.quotationFormBusinessPartner.cardName;
                    }
                } else if(userData.customerCNPJ.replace(/[.\-/]/g, '') === sapData.quotation.quotationFormBusinessPartner.cpf){
                    if (sapData.quotation.quotationFormBusinessPartner.cardName !== userData.customerName){
                        userData.customerName = sapData.quotation.quotationFormBusinessPartner.cardName;
                    }
                }
            }



            const result = await SapService.sendSAP(userData, type);
            Lockr.set('Loovi-SAP', result);
        } catch (error) {
            alert('Erro de Servidor. Por favor, tente novamente. (0x0004_SAP_sendSAP)');
            setIsLoading(false);
            return;
        }

        const resultPlan = await SapService.setPlan();
        if (!resultPlan) {
            alert('Erro de Servidor. Por favor, tente novamente. (0x0003_SAP_setPlan)');
            await setIsLoading(false);
            return;
        }

        Lockr.set('loovi-metrics', userData);
        
        await fireWebToLead({
            ...userData,
            type: type,
            customerCPForCNPJ: userData.customerCNPJ.replace(/[.\-/]/g, '')},'Dados de Entrega');

        setTimeout(async () => {
            window.emitter.emit('PriceChangeEvent');
            setIsLoading(false);
            props.nextStep();
        }, 20);
    }

    const clearAddress = () => {
/*         const clearFields = () => {
            fields.map(field => {
                props.handleChange({ name: field, value: '' })
            })
        }

        let fields = [
            'customerCEP',
            'customerStreet',
            'customerStreetNum',
            'customerAdressComplement',
            'customerNeighborhood',
            'customerUFCheck',
            'customerCityCheck',
        ]
        clearFields()

        fields = [
            'customerShippingCEP',
            'customerShippingStreet',
            'customerShippingStreetNum',
            'customerShippingAdressComplement',
            'customerShippingNeighborhood',
            'customerShippingUFCheck',
            'customerShippingCityCheck',
        ]
        clearFields()

        userData = {
            ...userData,
            customerName: '',
            customerCEP: '',
            customerCityCheck: '',
            customerNeighborhood: '',
            customerStreet: '',
            customerStreetNum: '',
            customerUFCheck: '',
            customerAdressComplement: ''
        }

        Lockr.set('loovi-metrics', userData) */


        setValue('customerCEP', '');
        setValue('customerStreet', '');
        setValue('customerStreetNum', '');
        setValue('customerAdressComplement', '');
        setValue('customerNeighborhood', '');
        setValue('customerUFCheck', '');
        setValue('customerCityCheck', '');
    }

    const getAddress = event => {
        const regexCEP = /[0-9]{5}-[\d]{3}/
        let cep = event.target.value

        let street = 'customerStreet'
        let number = 'customerStreetNum'
        let complement = 'customerAdressComplement'
        let neighborhood = 'customerNeighborhood'
        let uf = 'customerUF'
        let city = 'customerCity'
        let ufCheck = 'customerUFCheck'
        let cityCheck = 'customerCityCheck'
        let ibgeCode = 'customerIbgeCode'

        // Verificando se o preenchimento do CEP para é para o endereço de entrega
        const isShippingAddress = (event.target && event.target.name === 'customerShippingCEP')
        if (isShippingAddress) {
            street = 'customerShippingStreet'
            number = 'customerShippingStreetNum'
            complement = 'customerShippingAdressComplement'
            neighborhood = 'customerShippingNeighborhood'
            uf = 'customerShippingUF'
            city = 'customerShippingCity'
            ufCheck = 'customerShippingUFCheck'
            cityCheck = 'customerShippingCityCheck'
            ibgeCode = 'customerShippingIbgeCode'
        }

        if (regexCEP.test(cep) && !isLoading) {
            // Exibindo animação de carregamento
            setIsLoading(true)

            // Requisitando API
            CorreiosService.getAddress(cep.replace('-', ''))
                .then(data => {
                    let cepEstado = estados.filter(estado => (estado.value === data.UF));

                    if (ibgeCode === 'customerShippingIbgeCode') {
                        userData = {
                            ...userData,
                            customerShippingIbgeCode: data.CodigoIbge,
                        };
                        Lockr.set('loovi-metrics', userData);
                    } else {
                        userData = {
                            ...userData,
                            customerIbgeCode: data.CodigoIbge,
                        };
                        Lockr.set('loovi-metrics', userData);
                    }

                    // Preenchimento do endereço resultante do CEP informado
                    props.handleChange({ name: ibgeCode, value: data.CodigoIbge });
                    props.handleChange({ name: street, value: data.Logradouro });
                    props.handleChange({ name: number, value: '' });
                    props.handleChange({ name: complement, value: '' });
                    props.handleChange({ name: neighborhood, value: data.Bairro });
                    props.handleChange({
                        name: uf,
                        value: { label: cepEstado[0].label, value: cepEstado[0].value, cidades: cepEstado[0].cidades }
                    });
                    props.handleChange({
                        name: city,
                        value: { label: data.Municipio, value: data.Municipio }
                    });
                    props.handleChange({
                        name: ufCheck,
                        value: cepEstado[0].value
                    });
                    props.handleChange({
                        name: cityCheck,
                        value: data.Municipio
                    });

                    // Limpando mensagens de erro de preenchimento de campo
                    clearError(ufCheck)
                    clearError(cityCheck)
                    clearError(street)
                    clearError(neighborhood)

                    // Finalizando animação de carregamento
                    setIsLoading(false);
                    if (data.Logradouro && data.Bairro) {
                        if (isShippingAddress) setBlockedByCEPShipping(true);
                        else setBlockedByCEP(true);
                    } else {
                        if (isShippingAddress) setBlockedByCEPShipping(false);
                        else setBlockedByCEP(false);
                    }
                })
                .catch(function (error) {
                    alert("CEP não encontrado.")

                    props.handleChange({ name: street, value: '' });
                    props.handleChange({ name: neighborhood, value: '' });
                    props.handleChange({ name: uf, value: { label: '', value: '', cidades: [] } });
                    props.handleChange({ name: city, value: { label: '', value: '' } });
                    props.handleChange({ name: ufCheck, value: { label: '', value: '', cidades: [] } });
                    props.handleChange({ name: cityCheck, value: { label: '', value: '' } });

                    setIsLoading(false)
                    if (event.target && event.target.name === 'customerShippingCEP') setBlockedByCEPShipping(false);
                    else setBlockedByCEP(false);
                })

            fireWebToLead({
                ...userData,
                type: type,
                customerCPForCNPJ: values.customerCNPJ.replace(/[.\-/]/g, '')},'Dados Iniciais');
        }
    }

    const getAddressCompany = async () => {
       

        
        let result;
        const cpfOrCnpj = values.customerCNPJ.replace(/[.\-/]/g, '');

        if (!(cpfOrCnpj.length === 11 || cpfOrCnpj.length === 14) && !isLoading) return;

        setIsLoading(true);

        try {
            result = await CpfCnpjService.getCpfCnpj(cpfOrCnpj);
            clearError('customerCNPJ')

            if (type === 'CPF') {
                userData = {
                    ...userData,
                    customerName: result.Nome
                };
            } else {
                clearAddress();
                userData = {
                    ...userData,
                    customerName: result.RazaoSocial,
                    customerCEP: result.Endereco.Cep.replace('-', ''),
                    customerCityCheck: result.Endereco.Cidade,
                    customerNeighborhood: result.Endereco.Bairro,
                    customerStreet: result.Endereco.Logradouro,
                    customerStreetNum: result.Endereco.Numero,
                    customerAdressComplement: result.Endereco.Complemento,
                    customerUFCheck: result.Endereco.UF,
                };
            }

            Lockr.set('loovi-metrics', userData);

            await fireWebToLead({
                ...userData,
                type: type,
                customerCPForCNPJ: cpfOrCnpj}, 'Dados Iniciais');

        } catch (error) {
            
            let response;
            response = await error.response.json();
            alert(response.DescricaoErro);

            setError('customerCNPJ', 'invalid', 'Preencha com um CPF ou CNPJ válido!')
            setIsLoading(false);
            return false;
        }

        if (type === 'CPF') {
            setIsLoading(false);
            return;
        }

        try {

            const resultCorreios = await CorreiosService.getAddress(result.Endereco.Cep.replace('-', ''))
            const cepEstado = estados.filter(estado => (estado.value === result.Endereco.UF));

            userData = {
                ...userData,
                customerIbgeCode: resultCorreios.CodigoIbge,
                customerUF: {
                    label: cepEstado[0].label,
                    value: cepEstado[0].value,
                    cidades: cepEstado[0].cidades,
                },
                customerCity: {
                    label: result.Endereco.Cidade,
                    value: result.Endereco.Cidade,
                }
            };
            Lockr.set('loovi-metrics', userData);

            props.handleChange({ name: 'customerIbgeCode', value: resultCorreios.CodigoIbge });
            props.handleChange({ name: 'customerCEP', value: masks.cep9(result.Endereco.Cep.replace('-', '')) });
            props.handleChange({ name: 'customerStreet', value: result.Endereco.Logradouro });
            props.handleChange({ name: 'customerStreetNum', value: result.Endereco.Numero });
            props.handleChange({ name: 'customerNeighborhood', value: result.Endereco.Bairro });
            props.handleChange({ name: 'customerAdressComplement', value: result.Endereco.Complemento });
            props.handleChange({
                name: 'customerUF',
                value: {
                    label: cepEstado[0].label,
                    value: cepEstado[0].value,
                    cidades: cepEstado[0].cidades,
                }
            });
            props.handleChange({
                name: 'customerUFCheck',
                value: cepEstado[0].value,
            });
            props.handleChange({
                name: 'customerCity',
                value: {
                    label: result.Endereco.Cidade,
                    value: result.Endereco.Cidade,
                }
            });
            props.handleChange({
                name: 'customerCityCheck',
                value: result.Endereco.Cidade,
            });

            setValue('customerCEP', masks.cep9(result.Endereco.Cep.replace('-', '')));
            setValue('customerStreet', result.Endereco.Logradouro);
            setValue('customerStreetNum', result.Endereco.Numero);
            setValue('customerAdressComplement', result.Endereco.Complemento);
            setValue('customerNeighborhood', result.Endereco.Bairro);
            setValue('customerUFCheck', cepEstado[0].value);
            setValue('customerCityCheck', result.Endereco.Cidade);
        } catch (error) {
            console.error(error)
        }

        setIsLoading(false);
    }

    const handleChangeCheckbox = (status) => {


        props.handleChange({ name: 'customerShippingIbgeCode', value: '' });
        props.handleChange({ name: 'customerShippingCEP', value: '' });
        props.handleChange({ name: 'customerShippingStreet', value: '' });
        props.handleChange({ name: 'customerShippingStreetNum', value: '' });
        props.handleChange({ name: 'customerShippingNeighborhood', value: '' });
        props.handleChange({ name: 'customerShippingStreetNum', value: '' });
        props.handleChange({ name: 'customerShippingNeighborhood', value: '' });
        props.handleChange({
            name: 'customerShippingUF',
            value: { label: '', value: '', cidades: '', }
        });
        props.handleChange({
            name: 'customerShippingCity',
            value: { label: '', value: '', }
        });

        setHasAddressShipping(!status);
    }

    const handleChangeCPFAndCNPJ = (event) => {
        let cpfOrCnpj = event.target.value.trim();
        cpfOrCnpj = cpfOrCnpj.replace(/[\D]/g, '');

        let value;
        if (cpfOrCnpj.length <= 11) {
            setType('CPF')
            value = masks.cpf(cpfOrCnpj);
        } else {
            setType('CNPJ')
            setHasIe(true);
            setValue('hasIe', true)
            value = masks.cnpj(cpfOrCnpj);
        }

        setValue('customerCNPJ', value)
        props.handleChange(event)
    }

    const handleChangeCEP = (event) => {
        let cep = event.target.value;

        cep = cep.replace(/[\D]/g, '');

        let value;
        value = masks.cep9(cep);

        setValue('customerCEP', value)
        props.handleChange(event)
    }

    const handleChangeShippingCEP = (event) => {
        const cep = event.target.value;

        let value;
        value = masks.cep9(cep);

        setValue('customerShippingCEP', value)
        props.handleChange(event)
    }

    useEffect(() => {
        if (values.customerCNPJ && values.customerCNPJ.replace(/[.\-/]/g, '').length > 11) {
            getAddressCompany();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* <div className="loovi-radio-group" style={{display: 'flex'}}>
                    <label className="loovi-radio loovi-checkbox-light">
                        <input name="customerVehicle" type="radio" checked={type === 'CPF'} onChange={() => setType('CPF')}/>
                        <span className="checkmark"></span> CPF
                    </label>
                    <label className="loovi-radio loovi-checkbox-light">
                        <input name="customerVehicle" type="radio" checked={type === 'CNPJ'} onChange={() => setType('CNPJ')}/>
                        <span className="checkmark"></span> CNPJ
                    </label>
                </div> */}

                <label>
                    <span>CPF ou CNPJ</span>
                    <input
                        className={errors.customerCNPJ ? 'loovi-field loovi-field-error' : 'loovi-field'}
                        name="customerCNPJ"
                        onBlur={getAddressCompany}
                        onChange={handleChangeCPFAndCNPJ}
                        ref={register({ required: "Preencha o campo acima" })}
                        type="text"
                        defaultValue={values.customerCNPJ}
                    />
                    {errors.customerCPF && <span className="loovi-msg-error">Preencha o campo com um CPF válido.</span>}
                    {errors.customerCNPJ && <span className="loovi-msg-error">Preencha o campo com um CPF ou CNPJ válido.</span>}
                </label>

                {type === 'CNPJ' && (
                    <>
                        <label>
                            <span>Inscrição Estadual</span>
                            <input
                                className={errors.customerIE ? 'loovi-field loovi-field-error' : 'loovi-field'}
                                defaultValue={values.ie}
                                disabled={isIsetoIE}
                                name="ie"
                                onChange={props.handleChange}
                                ref={register({ required: "Preencha a inscrição estadual" })}
                                type="text"
                            />
                            {errors.customerIE && <span className="loovi-msg-error">Preencha a inscrição estadual</span>}
                        </label>
                        <label className="loovi-checkbox loovi-checkbox-light">
                            <span>Isento de IE</span>
                            <input
                                checked={isIsetoIE}
                                name="isIsetoIE"
                                onChange={toggleIsentoIE}
                                type="checkbox"
                            />
                            <span className="checkmark"></span>
                        </label>
                    </>
                )}
                <label>
                    <span>Cep</span>


                    <input
                        name="customerCEP"
                        onChange={handleChangeCEP}
                        onBlur={getAddress}
                        type="text"
                        ref={register({
                            required: "Preencha o campo acima",
                            minLength: {
                                value: 9,
                                message: "Preencha com no mínimo 9 caracteres"
                            },
                            pattern: /[0-9]{5}-[\d]{3}/
                        })}
                        disabled={disableFieldsByCNPJ}
                        className={errors.customerCEP ? 'loovi-field loovi-field-error' : 'loovi-field'}
                        defaultValue={values.customerCEP}
                    />
                    {errors.customerCEP && <span className="loovi-msg-error">Preencha o campo com um CEP válido</span>}
                    <br /><a href="http://www.buscacep.correios.com.br/sistemas/buscacep" rel="noopener noreferrer" target="_blank">Não sei o meu CEP</a>
                </label>
                <label>
                    <span>Rua</span>
                    <input
                        className={errors.customerStreet ? 'loovi-field loovi-field-error' : 'loovi-field'}
                        disabled={(disableFieldsByCNPJ || disableFieldsByCEP)}
                        name="customerStreet"
                        onChange={props.handleChange}
                        ref={register({ required: true })}
                        type="text"
                        defaultValue={values.customerStreet}
                    />
                    {errors.customerStreet && <span className="loovi-msg-error">Preencha o campo acima</span>}
                </label>

                <Row>
                    <Col>
                        <label>
                            <span>N&#186;</span>
                            <input
                                onChange={props.handleChange}
                                name="customerStreetNum"
                                type="text"
                                defaultValue={values.customerStreetNum}
                                disabled={disableFieldsByCNPJ}
                                className={errors.customerStreetNum ? 'loovi-field loovi-field-error' : 'loovi-field'}
                                ref={register({ required: true })} />
                            {errors.customerStreetNum && <span className="loovi-msg-error">Preencha o campo acima</span>}
                        </label>
                    </Col>
                    <Col>
                        <label>
                            <span>Complemento</span>
                            <input
                                onChange={props.handleChange}
                                name="customerAdressComplement"
                                type="text"
                                disabled={disableFieldsByCNPJ}
                                defaultValue={values.customerAdressComplement}
                                className='loovi-field'
                                ref={register({ required: false })}
                            />
                        </label>
                    </Col>
                </Row>
                <label>
                    <span>Bairro</span>
                    <input
                        onChange={props.handleChange}
                        name="customerNeighborhood"
                        type="text"
                        defaultValue={values.customerNeighborhood}
                        disabled={(disableFieldsByCNPJ || disableFieldsByCEP)}
                        className={errors.customerNeighborhood ? 'loovi-field loovi-field-error' : 'loovi-field'}
                        ref={register({ required: true })} />
                    {errors.customerNeighborhood && <span className="loovi-msg-error">Preencha o campo acima</span>}
                </label>
                <Row>
                    <Col>
                        <label>
                            <span>UF</span>
                            <input
                                autoComplete="none"
                                disabled
                                name="customerUFCheck"
                                ref={register({ required: true })}
                                type="text"
                                defaultValue={values.customerUF.value}
                            />
                            {errors.customerUFCheck && <span className="loovi-msg-error">Selecione seu Estado</span>}
                        </label>
                    </Col>
                    <Col>
                        <label>
                            <span>Cidade</span>
                            <input
                                autoComplete="none"
                                disabled
                                name="customerCityCheck"
                                ref={register({ required: true })}
                                type="text"
                                defaultValue={values.customerCity.value}
                            />
                            {errors.customerCityCheck && <span className="loovi-msg-error">Selecione sua Cidade</span>}
                        </label>
                    </Col>
                </Row>

                {(type === 'CPF') && (
                    <React.Fragment>
                        <span className="loovi-spacer"></span>
                        <label className="loovi-checkbox loovi-checkbox-light">
                            <span>O endereço de entrega é diferente deste</span>
                            <input
                                type="checkbox"
                                name="hasAddressShipping"
                                ref={register}
                                checked={hasAddressShipping}
                                onChange={() => {


                                    handleChangeCheckbox(hasAddressShipping)
                                }} />
                            <span className="checkmark"></span>
                        </label>
                    </React.Fragment>
                )}

                {hasAddressShipping && (
                    <React.Fragment>
                        <span className="loovi-spacer" />
                        <h2>Endereço de entrega</h2>
                        <label>
                            <span>Cep</span>
                            <input
                                name="customerShippingCEP"
                                onChange={handleChangeShippingCEP}
                                onBlur={getAddress}
                                ref={register({ pattern: /[0-9]{5}-[\d]{3}/ })}
                                type="text"
                                defaultValue={values.customerShippingCEP}
                                className={errors.customerShippingCEP ? 'loovi-field loovi-field-error' : 'loovi-field'} />
                            {errors.customerShippingCEP && <span className="loovi-msg-error">Preencha o campo com um CEP válido</span>}
                            <br /><a href="http://www.buscacep.correios.com.br/sistemas/buscacep" rel="noopener noreferrer" target="_blank">Não sei o meu CEP</a>
                        </label>
                        <label>
                            <span>Rua</span>
                            <input
                                name="customerShippingStreet"
                                ref={register({ required: true })}
                                onChange={props.handleChange}
                                type="text"
                                disabled={disableFieldsByCEPShipping}
                                defaultValue={values.customerShippingStreet}
                                className={errors.customerShippingStreet ? 'loovi-field loovi-field-error' : 'loovi-field'} />
                            {errors.customerShippingStreet && <span className="loovi-msg-error">Preencha o campo acima</span>}
                        </label>
                        <Row>
                            <Col>
                                <label>
                                    <span>N&#186;</span>
                                    <input
                                        name="customerShippingStreetNum"
                                        ref={register({ required: true })}
                                        onChange={props.handleChange}
                                        type="text"
                                        defaultValue={values.customerShippingStreetNum}
                                        className={errors.customerShippingStreetNum ? 'loovi-field loovi-field-error' : 'loovi-field'} />
                                    {errors.customerShippingStreetNum && <span className="loovi-msg-error">Preencha o campo acima</span>}
                                </label>
                            </Col>
                            <Col>
                                <label>
                                    <span>Complemento</span>
                                    <input
                                        onChange={props.handleChange}
                                        name="customerShippingAdressComplement"
                                        type="text"
                                        defaultValue={values.customerShippingAdressComplement}
                                        className='loovi-field'
                                        ref={register({ required: false })}
                                    />
                                </label>
                            </Col>
                        </Row>
                        <label>
                            <span>Bairro</span>
                            <input
                                onChange={props.handleChange}
                                name="customerShippingNeighborhood"
                                ref={register({ required: true })}
                                type="text"
                                disabled={disableFieldsByCEPShipping}
                                defaultValue={values.customerShippingNeighborhood}
                                className={errors.customerShippingNeighborhood ? 'loovi-field loovi-field-error' : 'loovi-field'} />
                            {errors.customerShippingNeighborhood && <span className="loovi-msg-error">Preencha o campo acima</span>}
                        </label>
                        <Row>
                            <Col>
                                <label>
                                    <span>UF</span>
                                    <input
                                        autoComplete="none"
                                        disabled
                                        name="customerShippingUFCheck"
                                        ref={register({ required: true })}
                                        type="text"
                                        defaultValue={values.customerShippingUF.value}
                                    />
                                    {errors.customerShippingUFCheck && <span className="loovi-msg-error">Selecione seu Estado</span>}
                                </label>
                            </Col>
                            <Col>
                                <label>
                                    <span>Cidade</span>
                                    <input
                                        autoComplete="none"
                                        disabled
                                        name="customerShippingCityCheck"
                                        ref={register({ required: true })}
                                        type="text"
                                        defaultValue={values.customerShippingCity.value}
                                    />
                                    {errors.customerShippingCityCheck && <span className="loovi-msg-error">Selecione sua Cidade</span>}
                                </label>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}


                <input className="loovi-button" type="submit" value="Confirmar" />
            </form>
            <span className="loovi-spacer" />
            <input className="loovi-link-button" onClick={props.prevStep} type="button" value="Voltar" />
            <LoaderAnimation show={isLoading} />
        </React.Fragment>
    )
}

export class FormCPF extends React.Component {

    render() {

        const {
            handleChange,
            handleSelectChange,
            nextStep,
            prevStep,
            values,
        } = this.props

        return (
            <Section>
                <Header background="#5A78FF" padding logo />
                <Container>
                    <Row>
                        <Col sm={12} md={{ span: 4, offset: 4 }}>
                            <Body>
                                <h2>Seus dados</h2>
                                <Form
                                    handleChange={handleChange}
                                    handleSelectChange={handleSelectChange}
                                    nextStep={nextStep}
                                    prevStep={prevStep}
                                    values={values}
                                />
                            </Body>
                        </Col>
                    </Row>
                </Container>
            </Section>
        )
    }
}

const Section = styled.section`
    position: relative;

    h1, h5, p{

        text-align: left !important;
    }

    h2{

        color: "#5A78FF";
        font-size: 24px !important;
        color: #5A78FF;
        letter-spacing: -1px;
        text-align: center;
        line-height: 30px;
        margin: 0;
    }

    .loovi-user-info{

        margin: 35px 0;

        p{

            margin: 0;
            font-size: 15px;
            color: #899299;
            letter-spacing: -0.32px;
            line-height: 21px;
        }
    }
`

export default FormCPF
