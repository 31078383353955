// Dependencias

import React, { useState, useEffect } from 'react'
import Lockr from 'lockr'
import styled from 'styled-components'
import useForm from 'react-hook-form'
import * as masks from '../../helpers/masks'

// Componentes

import { Container, Row, Col } from 'react-bootstrap'
import Header from '../Header'
import Body from '../Body'
import LoaderAnimation from '../LoaderAnimation'
import MagicService from '../../services/MagicService'
import { updateDataLayer } from '../../helpers/DataLayer'

const Form = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, errors, setValue } = useForm()

    useEffect(() => {
        const sapData = Lockr.get('Loovi-SAP');
        window.parent.postMessage({ mensagem: 'CHECKOUT-LOOVI-FINALIZADO' }, '*');
        const eventFired = sessionStorage.getItem('PurchaseFired');
  
        if (!eventFired) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'Purchase',
            transactionId: sapData.quotation.quotationForm.quotID,
          });

          sessionStorage.setItem('PurchaseFired', 'true');
        }
        
      }, []);

    const onSubmit = async (data) => {

        setIsLoading(true);
        const userData = Lockr.get('Loovi-SAP');
        try{
            const result = await MagicService.sendPlate(data.customerLicencePlate, userData.quotation.quotationForm.quotID);
            if (result) {
                if (result.retorno !== "FALSE" && result.retorno !== "false") {
                    window.plate = data.customerLicencePlate;
                    window._etmc.push(["setOrgId", "110005701"]);
                    window._etmc.push(["trackPageView"]);
                    props.proceed();
                    setIsLoading(false);
                } else {
                    alert('Oops, ocorreu um erro ao associar a placa, tente novamente mais tarde.');
                    setIsLoading(false);
                }
            } else {
                alert('Oops, ocorreu um erro ao associar a placa, tente novamente mais tarde.');
                setIsLoading(false);
            }
        }catch(e){
            alert('Oops, ocorreu um erro ao associar a placa, tente novamente mais tarde.');
            setIsLoading(false);
        }
    }



    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <LoaderAnimation show={isLoading} />
            <label>
                <span>Placa do Veículo</span>
                <input
                  name="customerLicencePlate"
                  type="text"
                  onChange={(e) => {
                    let value = masks.plate(e.target.value);
                    setValue('customerLicencePlate', value);
                    props.handleChange(e);
                  }}
                  ref={register({ pattern: /[a-zA-Z]{3}[0-9]{1}[0-9a-zA-Z]{1}[0-9]{2}/ })}
                  className={errors.customerLicencePlate ? 'loovi-field loovi-field-error' : 'loovi-field'} />
            </label>
            {errors.customerLicencePlate && <span className="loovi-msg-error">Insira placa válida</span>}
            <input type="submit" value="Enviar" />
        </form>
    )
}

export class FormLicencePlate extends React.Component {

    proceed = () => {
        updateDataLayer({"status_da_compra": window.plate ? 'USUARIO_PREENCHEU_PLACA' : 'USUARIO_NAO_PREENCHEU_PLACA', "placa_veiculo": window.plate || 'Não informada' });
        window._etmc.push(["setOrgId", "110005701"]);
        window._etmc.push(["trackPageView"]);
        window.plate = null;
        this.props.nextStep()
    }

    render() {

        const { handleChange } = this.props

        return (
            <Section>
                <Header background="#5A78FF" padding logo>
                    <h2>Placa do veículo</h2>
                    <p>Agora insira a placa do seu veículo</p>
                </Header>
                <Container>
                    <Row>
                        <Col sm={12} md={{ span: 4, offset: 4 }}>
                            <Body>
                                <Form handleChange={handleChange} proceed={this.proceed} />
                                <span className="loovi-spacer"></span>
                                <div className="loovi-center">
                                    <input className="loovi-link-button" onClick={this.proceed} type="button" value="Informar a placa depois" />
                                </div>
                            </Body>
                        </Col>
                    </Row>
                </Container>
            </Section>
        )
    }
}

const Section = styled.section`
    position: relative;

    .loovi-center{

        text-align: center;

        a{

            font-family: BrownStd-Bold;
            font-size: 15px;
            color: #5A78FF;
            letter-spacing: -0.32px;
            line-height: 21px;
            border-bottom: solid 2px #5A78FF;

            &:hover{

                color: #57D9A3;
                border-bottom: solid 2px #57D9A3;
            }
        }
    }
`

export default FormLicencePlate