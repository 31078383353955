import { api_keyed, URLS } from '../helpers/Request';

export default class CpfCnpjService {
  static async getCpfCnpj(cpfCnpj) {
    try {
      return await api_keyed.get(`${URLS.CPF_CNPJ}/${cpfCnpj}`).json();
    } catch (e) {
      console.log(e);
      throw e
    }
  }

}
