import React from 'react';
import styled from 'styled-components';
import { Logo } from './components/Logo';
import { useQuery } from './helpers/useQuery';
import Voucher from './components/Voucher';
import { SectionSlider } from './components/home/SectionSlider';
import { Container, Col, Row } from 'react-bootstrap';

import iconConfetti from './assets/images/icon-confetti.svg';
import backgroundCar from './assets/images/car-wallpaper.jpg';
import iconSatisfaction from './assets/images/icon_satisfaction.svg';
import looviLine from './assets/images/loovi-line.svg';
import mapIcon from './assets/images/map-icon.svg';
import phoneIcon from './assets/images/phone-icon.svg';
import iconLock from './assets/images/icon-lock.svg';
import batteryIcon from './assets/images/battery-icon.svg';
import iconCar from './assets/images/icon-car.svg';
import phoneImage from './assets/images/phone_image.svg';
import { Footer } from './components/Footer'

export const VoucherIndication = () => {
  const query = useQuery();
  const code = query.get('promocode');
  const name = query.get('nome');

  function useVoucher() {
    sessionStorage.setItem('LOOVI_VOUCHER_CODE', code);
    setTimeout(() => {
      window.location.href = '/contact';
    }, 16);
  }

  return (
    <MainContainer>
      <BannerContainer>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        {!isMobile() && <GreenBorderCircle />}

        <SmallGreenCircle />
        <SmallGreenCircleRight />
        <RingWhiteCircle />
        <GreenBottomCircle />
        {!isMobile() && <Confetti src={iconConfetti} alt="icon-confetti" />}
        <BannerTitle>
          {name ? name : 'Alguém'} te enviou um voucher de R$ 99,90 para você usar a garantia Loovi no seu carro.
        </BannerTitle>
        {isMobile() ? (
          <div>
            <Voucher code={code && code} />
          </div>
        ) : (
            <VoucherContainer>
              <Voucher code={code && code} />
            </VoucherContainer>
          )}

        {isMobile() ? (
          <ButtonContainerMobile>
            <Button onClick={useVoucher}>USAR MEU VOUCHER</Button>
          </ButtonContainerMobile>
        ) : (
            <ButtonContainer>
              <Button onClick={useVoucher}>USAR MEU VOUCHER</Button>
            </ButtonContainer>
          )}


      </BannerContainer>
      <SliderContainer>
        <SectionSlider />
      </SliderContainer>

      {isMobile() ? (
        <GuaranteeContainerMobile>
          <img style={{ height: 'fit-content' }} src={looviLine} alt="loovi line" />
          <GuaranteeTitle>Achamos ou pagamos</GuaranteeTitle>
          <GuaranteeSimpleText style={{ fontSize: '20px' }}>
            Localizamos o seu carro roubado mas,
            se por algum motivo não encontrarmos,
            pode ficar tranquilo. A Loovi te dá outro
            de volta.  ; - )
          </GuaranteeSimpleText>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Satisfaction src={iconSatisfaction} alt="Satifaction" />
          </div>
        </GuaranteeContainerMobile>

      ) : (
          <GuaranteeContainer>
            <Container>
              <Row>
                <Col>
                  <GuaranteeLeftText>
                    <img src={looviLine} alt="loovi line" />
                    <GuaranteeTitle>Achamos ou pagamos</GuaranteeTitle>
                    <GuaranteeSimpleText style={{ fontSize: '20px' }}>
                      Localizamos o seu carro roubado mas,
                      se por algum motivo não encontrarmos,
                      pode ficar tranquilo. A Loovi te dá outro
                      de volta.  ; - )
                </GuaranteeSimpleText>
                    <Satisfaction src={iconSatisfaction} alt="Satifaction" />
                  </GuaranteeLeftText>
                </Col>
                <Col>
                  <GuaranteeLeftText>
                    <GuaranteeSimpleText>
                      A Loovi nasceu junto com essa nova geração de empresas
                      como a Uber, Netflix e Nubank que tem mudado o formato
                      tradicional dos serviços.
              </GuaranteeSimpleText>
                    <GuaranteeSimpleText>
                      Diferente do seguro de carros tradicional, não cobrimos
                      colisão ou batidas e focamos apenas no que as pessoas
                      mais se preocupam, o roubo do veículo. Com isso,
                      conseguimos um preço justo para dar acesso a todas as
                      pessoas com tecnologia.
              </GuaranteeSimpleText>
                    <GuaranteeSimpleText>
                      Nosso processo é simples e seguro: Localizamos seu veículo
                      em tempo real, se ele for roubado: Achamos ou Pagamos
                      100% da tabela FIPE até o teto máximo de R$ 60 mil reais.
              </GuaranteeSimpleText>
                  </GuaranteeLeftText>
                </Col>
              </Row>
            </Container>
          </GuaranteeContainer>
        )}


      <BonusSectionContainer>
        {!isMobile() && <GreenBorderCircle style={{ top: '130px' }} />}
        {!isMobile() && <SmallBlueCircleRight />}
        {!isMobile() && <RingBlueCircle />}
        {!isMobile() && <GreenBottomCircle />}

        {isMobile() ? (
          <PhoneImageMobile src={phoneImage} alt="Phone" />
        ) : (
            <PhoneImage src={phoneImage} alt="Phone" />
          )}

        <Container>
          {isMobile() ? (
            <>
              <BonusTitle style={{ paddingTop: '420px' }}>Bônus e muito mais</BonusTitle>
              <BonusDescription>
                Além da garantia contra roubo, oferecemos
                de bônus para o seu veículo:
                </BonusDescription>
              <Row>
                <Col>
                  <div style={{ marginTop: '40px' }}>
                    <BenefitIcon src={mapIcon} />
                    <BenefitDescription>
                      Lista dos seus <br /> trajetos
                  </BenefitDescription>
                  </div>
                  <div style={{ marginTop: '40px' }}>
                    <BenefitIcon src={iconCar} />
                    <BenefitDescription>
                      Localização <br /> em tempo real
                        </BenefitDescription>
                  </div>
                  <div style={{ marginTop: '40px', }}>
                    <BenefitIcon src={iconLock} />
                    <BenefitDescription>
                      Alerta de <br /> Movimento
                  </BenefitDescription>
                  </div>
                </Col>
                <Col>
                  <div style={{ marginTop: '40px' }}>
                    <BenefitIcon src={phoneIcon} />
                    <BenefitDescription>
                      Aplicativo <br /> gratuito
                  </BenefitDescription>
                  </div>
                  <div style={{ marginTop: '40px' }}>
                    <BenefitIcon src={batteryIcon} />
                    <BenefitDescription>
                      Nível de <br /> bateria
                  </BenefitDescription>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
              <Row>
                <Col>
                  <LeftBonus>
                    <BonusTitle>Bônus e muito mais</BonusTitle>
                    <BonusDescription>
                      Além da garantia contra roubo, oferecemos
                      de bônus para o seu veículo:
                </BonusDescription>
                    <BonusBenefitsContainer>
                      <div style={{ marginTop: '40px' }}>
                        <BenefitIcon src={mapIcon} />
                        <BenefitDescription>
                          Lista dos seus <br /> trajetos
                  </BenefitDescription>
                      </div>
                      <div style={{ marginTop: '40px' }}>
                        <BenefitIcon src={phoneIcon} />
                        <BenefitDescription>
                          Aplicativo <br /> gratuito
                  </BenefitDescription>
                      </div>

                      <div style={{ marginTop: '40px', }}>
                        <BenefitIcon src={iconLock} />
                        <BenefitDescription>
                          Alerta de <br /> Movimento
                  </BenefitDescription>
                      </div>
                    </BonusBenefitsContainer>
                    <BonusBenefitsContainer>
                      <div style={{ marginTop: '40px' }}>
                        <BenefitIcon src={iconCar} />
                        <BenefitDescription>
                          Localização <br /> em tempo real
                        </BenefitDescription>
                      </div>
                      <div style={{ marginTop: '40px' }}>
                        <BenefitIcon src={batteryIcon} />
                        <BenefitDescription>
                          Nível de <br /> bateria
                  </BenefitDescription>
                      </div>

                      <div style={{ width: '115.78px', marginTop: '40px' }}></div>
                    </BonusBenefitsContainer>
                    <ButtonContainer style={{ justifyContent: 'flex-start' }}>
                      <Button>CONTRATAR AGORA</Button>
                    </ButtonContainer>
                  </LeftBonus>
                </Col>
                <Col></Col>
              </Row>
            )}

        </Container>
      </BonusSectionContainer>
      <Footer />
    </MainContainer>
  );
}

const GreenBottomCircle = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #57D9A3;
  position: absolute;
  z-index: 0;
  bottom: -85px;
  right: 110px;
`;

const RingBlueCircle = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 24px solid #5A77FF;
    position: absolute;
    z-index: 1;
    right: -60px;
    bottom: 120px;

    @media (max-width: 768px){
        margin-left: -381px;
        bottom: -100px;
    }
`;

const SmallBlueCircleRight = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #5A77FF;
  position: absolute;
  z-index: 1;
  bottom: 190px;
  left: 58px;
`;

const PhoneImage = styled.img`
  position: absolute;
  top: -173px;
  right: -345px;
`;

const PhoneImageMobile = styled.img`
  position: absolute;
  max-width: none;
  width: 800px;
  top: -200px;
  left: -69px;
`;

const BenefitDescription = styled.div`
  color: #899299;
  font-size: 18px;
  margin-top: 10px;
`;

const BenefitIcon = styled.img`

`;

const BonusBenefitsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BonusDescription = styled.div`
  color: #899299;
  font-size: 23px;
`;

const LeftBonus = styled.div`
  margin-top: 140px;
`;

const BonusTitle = styled.h2`
  color: #393043;
`;

const BonusSectionContainer = styled.div`
  position: relative;
  background-color: #ffffff;
  padding-bottom: 100px;
`;

const RingWhiteCircle = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 20px solid #ffffff;
    position: absolute;
    z-index: 1;
    right: -60px;
    bottom: 120px;

    @media (max-width: 768px){
        margin-left: -381px;
        bottom: -100px;
    }
`;

const Satisfaction = styled.img`
  margin-top: 30px;
`;

const GuaranteeSimpleText = styled.div`
  color: #ffffff;
  font-size: 17px;
  margin-top: 20px;
`;

const GuaranteeLeftText = styled.div`
  color: #ffffff;
`;

const GuaranteeTitle = styled.h1`
  color: #ffffff;
  margin-top: 30px;
`;

const GuaranteeContainer = styled.div`
  background-image: url(${backgroundCar});
  background-size: cover;
  height: 700px;
  display: flex;
  align-items: center;
`;

const GuaranteeContainerMobile = styled.div`
  background-image: url(${backgroundCar});
  background-size: cover;
  height: 800px;
  padding-top: 77px;
  padding-left: 30px;
  padding-right: 30px;
`;

const SliderContainer = styled(Container)`
  background-color: #EFF2F7;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ButtonContainerMobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 117px; 
`;

const Button = styled.a`
  background-color: #57D9A3;
  color: #393043;
  padding: 15px;
  padding-right: 40px;
  padding-left: 40px;
  border-radius: 3px;
  bottom: 45px;
  left: 62px;
  cursor: pointer;
  margin-top: 80px;

  &:hover {
    background-color: #393043;
    color: white !important;
  }
`;

const VoucherContainer = styled(Container)`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;

const GreenBorderCircle = styled.div`
  width: 213px;
    top: 70px;
    left: -120px;
    height: 213px;
    border-radius: 50%;
    border: 32px solid #57D9A3;
    position: absolute;
`;

const SmallGreenCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #57D9A3;
  position: absolute;
  z-index: -1;
  top: 400px;
  left: 70px;
`;

const SmallGreenCircleRight = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #57D9A3;
  position: absolute;
  z-index: 1;
  top: 150px;
  right: 40px;
`;
const MainContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

const Confetti = styled.img`
  position: absolute;
  left: 50%;
  width: 400px;
  height: 400px;
  top: -35px;
  margin-left: -200px;
  z-index: -1;
`;

const LogoContainer = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    height: 40px;
    margin-top: 50px;

  }
`;

const BannerContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: #5a77ff;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-bottom: 100px;
  height: ${isMobile() && '900px'};
`;

const BannerTitle = styled.div`
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
  font-size: 35px;
  width: 500px;
  line-height: inherit;
  position: relative;

  @media only screen and (max-width: 768px) {
    width: 222px;
    font-size: 17px;
    font-weight: 600;
  }
`;

function isMobile() {
  return window.innerWidth < 768;
}

var currentLocation = window.location.pathname;
if (currentLocation === '/presente') {
  document.body.style.padding = '0'
}