import Lockr from 'lockr';
import { authHandler } from "../../helpers/auth";
import { api, URLS, api_sap, API_BANCO, USER_SAP } from "../../helpers/Request";

async function getContratos() {
    let dados = { Contratos: [] }
    let contratos = { Contratos: [] }
    const cpfOrCnpj = authHandler.getCPForCNPJ() || ''

    await getToken();

    if (dados.Contratos.length === 0 && cpfOrCnpj) {

        try {
            dados = await api_sap.get(`${URLS.SAP}/GetClient/${cpfOrCnpj}/${API_BANCO}`).json();

            //Filtra contratos ativos (não cancelados)
            dados.businessPartner.salesContracts = dados.businessPartner.salesContracts.filter((contract) => {
              return contract.cancelled === false;
            })

            if (dados.businessPartner.salesContracts.length > 0 ) {
              const selectedCar = dados.businessPartner.salesContracts
                  .map(contract => ({
                      ...contract.vehicle,
                      contractInfo: contract,
                  }))
                  .map((vehicle, key) => ({
                      contractInfo: vehicle.contractInfo,
                      label: vehicle.vModel ? `${vehicle.numberPlate} - ${vehicle.vModel}`.toUpperCase() : vehicle.numberPlate ? vehicle.numberPlate.toUpperCase() : `Dispositivo ${key + 1}`,
                      value: `${key}-${vehicle.numberPlate}`,
                    }));

              let customerSelectedCar
              const clientData = Lockr.get('ClientData')
              if (!!clientData && !!clientData.customerSelectedCar) customerSelectedCar = clientData.customerSelectedCar;
              else customerSelectedCar = selectedCar[0];

              const nCtrId = customerSelectedCar.contractInfo.nCtrId
              contratos = await getIndications(nCtrId)
              customerSelectedCar = {
                ...customerSelectedCar,
                contractDiscounts: contratos.activeIndicationCtt,
              }

              Lockr.set('ClientData', {
                  IdCustomerSelectedCar: customerSelectedCar.value,
                  customerCars: selectedCar,
                  customerSelectedCar: customerSelectedCar,
                  customerInfo: {
                      key: dados.businessPartner.addressType,
                      name: dados.businessPartner.cardName,
                      email: dados.businessPartner.e_Mail,
                      phone: dados.businessPartner.phone1,
                      cpf: dados.businessPartner.fiscalID.cpf,
                      cnpj: dados.businessPartner.fiscalID.cnpj,
                      address: dados.businessPartner.bpAddresses
                  }
              });
            }
            return;
        } catch (error) {
            console.error(error)
            return;
        }
    }
};


async function haveContracts(cpfOrCnpj) {
  let dados = { Contratos: [] }

  await getToken();

  if (dados.Contratos.length === 0 && cpfOrCnpj) {
      try {
          dados = await api_sap.get(`${URLS.SAP}/GetClient/${cpfOrCnpj}/${API_BANCO}`).json();

          //Filtra contratos ativos (não cancelados)
          dados.businessPartner.salesContracts = dados.businessPartner.salesContracts.filter((contract) => {
            return contract.cancelled === false;
          })

          if (dados.businessPartner.salesContracts.length > 0 ) {
            return true;
          } else {
            return false;
          }
      } catch (error) {
          console.error(error)
          return false;
      }
  } else {
    return false;
  }
}

async function getCotation(quotId) {
  await getToken();

  try {
    const result = await api_sap.get(`${URLS.SAP}/GetServOnlinePrice`, {
      headers: {
        quotId,
      }
    }).json();

    return result;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

async function getIndications(contractId) {

    await getToken();
    try
    {
      const result = await api_sap.get(`${URLS.SAP}/checkindicationctt`, {
        headers: {
          'nctrid': contractId
        }
      }).json();

      return result;
    } catch (e) {
      console.log(e);

      return false;
    }
  }

const getHistoricoDePagamento = async (nCtrId) => {
    const cpfOrCnpj = authHandler.getCPForCNPJ() || ''

    try {
      await getToken();
      const pagamentos = await api_sap.get(`${URLS.SAP}/CheckBills`, {
          headers: {
              banco: API_BANCO,
              cpfcnpj: cpfOrCnpj,
              nCtrId: nCtrId,
              // Requester: "SevenApp",
          }
      }).json();

      return pagamentos.returnBills;
    } catch (error) {
      console.error(error)
      return;
    }
}

async function getToken() {
  try {
    const result = await api.get(`${URLS.SAP.replace('/Loovi', '/Sap')}/GetToken`, {
      headers: {
        'Authorization': `Basic ${btoa(`${USER_SAP}`)}`
      }
    }).json();

    if (result.error) {
      alert(1);
      throw new Error(result.error);
    }

    window['LOOVI_SAP_TOKEN'] = result.token;

  } catch(e) {
    alert('Não foi possível comunicar com nossos servidores. Por favor, tente novamente. (0x_2392493_authSAP)');
    console.log(e);
  }
}

export { getCotation, getContratos, getIndications, getHistoricoDePagamento, haveContracts }
