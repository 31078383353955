import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderImage1 from '../../assets/images/EduardoCostaCantorSertajeno.jpg'
import sliderImage2 from '../../assets/images/KakaDinizDigitalInfluencer.jpg'
import sliderImage3 from '../../assets/images/LuanJogadordeFutebol.jpg'
import sliderImage4 from '../../assets/images/LucinhoBarretoPastordeJovens.jpg'
import sliderImage6 from '../../assets/images/TirulipaHumorista.jpg'
import sliderImage7 from '../../assets/images/WilliamBigodeJogadordeFutebol.jpg'


export const SectionSlider = () => (
  <Section>
    <Row>
      <Col sm={12} md={{ span: 10, offset: 2 }}>
        <SectionContent>
          <Row>
            <Col md={12} lg={{ span: 4, offset: 1 }}>
              <h3>Olha quem já está usando?</h3>
            </Col>
            <Col md={12} lg={7}>
              <h5 className="loovi-uppercase loovi-color-4">#amoloovi</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <SliderContainer>
                <SimpleSlider />
              </SliderContainer>
            </Col>
          </Row>
        </SectionContent>
      </Col>
    </Row>
  </Section>
)

const Section = styled.section`
    position: relative;
    z-index: 2;
`
const SectionContent = styled.div`
  position: relative;
  background: #EFF2F7;
  padding: 100px 0;

  h5{

    margin-top: 10px;
  }
  
  &::before{

    content: '';
    display: block;
    height: 100%;
    width: 1000px;
    background: #EFF2F7;
    position: absolute;
    top: 0;
    right: -100%;

    @media (max-width:768px){

      right: -1000px;
    }
  }

  @media (max-width:768px){

    padding: 50px 0 80px 0;

    h3, h5{

      margin-left: 30px;
    }

    h3{

      font-size: 32px;
    }
  }
`

const SliderContainer = styled.div`
  position: relative;
  margin-left: -100px;

  @media (max-width:768px){

    margin-left: -40px;
  }

  .slick-dots{

    text-align: right;
  }

  .slick-dots li.slick-active button:before{

    color: #57D9A3;
  }

  .slick-dots li button:before {
  
    font-size: 9px;
  }

  .slick-prev:before, .slick-next:before{

    color: #5A78FF;
  }

  .loovi-item{

    padding: 15px;
    text-align: right;

    img{

      margin-bottom: 20px;
    }

    h4{

      font-size: 18px;
      margin: 0;
      font-family: 'BrownStd-Regular';
    }

    p{

      font-size: 15px;
      margin: 0;
    }
  }
`

class SimpleSlider extends React.Component {
  render() {
    var settings = {
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
    return (
      <Slider {...settings}>
        <div className="loovi-item">
          <img src={sliderImage1} alt="Slider 1" />
          <h4>Eduardo Costa</h4>
          <p className="loovi-color-4">Cantor Sertanejo</p>
        </div>
        <div className="loovi-item">
          <img src={sliderImage2} alt="Slider 2" />
          <h4>Kaka Diniz</h4>
          <p className="loovi-color-4">Digital Influencer</p>
        </div>
        <div className="loovi-item">
          <img src={sliderImage3} alt="Slider 3" />
          <h4>Luan</h4>
          <p className="loovi-color-4">Jogador de Futebol</p>
        </div>
        <div className="loovi-item">
          <img src={sliderImage4} alt="Slider 4" />
          <h4>Lucinho Barreto</h4>
          <p className="loovi-color-4">Pastor de Jovens</p>
        </div>
        <div className="loovi-item">
          <img src={sliderImage6} alt="Slider 6" />
          <h4>Tirulipa</h4>
          <p className="loovi-color-4">Humorista</p>
        </div>
        <div className="loovi-item">
          <img src={sliderImage7} alt="Slider 7" />
          <h4>William Bigode</h4>
          <p className="loovi-color-4">Jogador de Futebol</p>
        </div>
      </Slider>
    );
  }
}