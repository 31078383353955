import Lockr from 'lockr';
import { api_keyed, URLS } from '../helpers/Request';

export default class SapService {
  static async initSell() {

    let encodedString = `{"url": "${URLS.SAP}/ServOnlinePrice", "metodo": "POST", "headers" : { "requester": "SevenApp" }, "body": { "quotationFormOrigem": { "online": true, "human": false, "app": false }}}`;
    encodedString = JSON.stringify(JSON.parse(encodedString),null,4);

    const utf8Bytes = encodeURIComponent(encodedString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode('0x' + p1);
    });

    encodedString = btoa(utf8Bytes);

    try {
      const result = await api_keyed.post(`${URLS.URLPROXY}/SAP`, {json: encodedString }).json();
      const dados = JSON.parse(atob(result));

      return dados;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  static async sendSAP(userData, type) {

    try {
      const sapData = Lockr.get('Loovi-SAP');

      sapData.quotation.quotationForm.state = userData.customerUFCheck;
      sapData.quotation.quotationForm.zipCode = userData.customerCEP;
      sapData.quotation.quotationFormBusinessPartner.email = userData.customerEmail;
      sapData.quotation.quotationFormBusinessPartner.cardName = userData.customerName;
      sapData.quotation.quotationFormBusinessPartner.phone1 = userData.customerPhoneNumber.replace(/[() \-/]/g, '');
      sapData.quotation.quotationFormBusinessPartner.cpf = (type === 'CPF') ? userData.customerCNPJ.replace(/[.\-/]/g, '') : '';
      sapData.quotation.quotationFormBusinessPartner.cnpj = (type === 'CNPJ') ? userData.customerCNPJ.replace(/[.\-/]/g, '') : '';
      sapData.quotation.quotationFormBusinessPartner.ie = userData.hasIe ? userData.ie : '';

      sapData.quotation.quotationFormAddressPayment.building = userData.customerAdressComplement;
      sapData.quotation.quotationFormAddressPayment.ibgeCode = userData.customerIbgeCode;
      sapData.quotation.quotationFormAddressPayment.addrType = 'S';
      sapData.quotation.quotationFormAddressPayment.street = userData.customerStreet;
      sapData.quotation.quotationFormAddressPayment.streetNo = userData.customerStreetNum;
      sapData.quotation.quotationFormAddressPayment.block = userData.customerNeighborhood;
      sapData.quotation.quotationFormAddressPayment.city = userData.customerCityCheck;
      sapData.quotation.quotationFormAddressPayment.zipCode = userData.customerCEP;
      sapData.quotation.quotationFormAddressPayment.state = userData.customerUFCheck;
      sapData.quotation.quotationFormAddressPayment.country = 'BR';

      sapData.quotation.quotationFormAddressDelivery.building = userData.customerShippingAdressComplement || userData.customerAdressComplement;
      sapData.quotation.quotationFormAddressDelivery.ibgeCode = userData.customerShippingIbgeCode || userData.customerIbgeCode;
      sapData.quotation.quotationFormAddressDelivery.addrType = 'B';
      sapData.quotation.quotationFormAddressDelivery.street = userData.customerShippingStreet || userData.customerStreet;
      sapData.quotation.quotationFormAddressDelivery.streetNo = userData.customerShippingStreetNum || userData.customerStreetNum;
      sapData.quotation.quotationFormAddressDelivery.block = userData.customerShippingNeighborhood || userData.customerNeighborhood;
      sapData.quotation.quotationFormAddressDelivery.city = userData.customerShippingCityCheck || userData.customerCityCheck;
      sapData.quotation.quotationFormAddressDelivery.zipCode = userData.customerShippingCEP || userData.customerCEP;
      sapData.quotation.quotationFormAddressDelivery.state = userData.customerShippingUFCheck || userData.customerUFCheck;
      sapData.quotation.quotationFormAddressDelivery.country = 'BR';

      sapData.quotation.quotationForm.cmpgnID = ''; //Limpa campanha caso exista

      sapData.quotation.quotationFormFreight = []; //Limpa fretes

      let encodedString = `{"url": "${URLS.SAP}/ServOnlinePrice", "metodo": "POST", "headers" : { "requester": "SevenApp" }, "body": ${JSON.stringify(sapData.quotation)}}`;

      encodedString = JSON.stringify(JSON.parse(encodedString),null,4);

      const utf8Bytes = encodeURIComponent(encodedString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
      });

      encodedString = btoa(utf8Bytes);

      const result = await api_keyed.post(`${URLS.URLPROXY}/SAP`, { json: encodedString }).json();      
      const dados = JSON.parse(atob(result));

      if (!dados.sucess) throw dados.message;
      return dados;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  static async registerUser(userData) {

    try {
      const sapData = Lockr.get('Loovi-SAP');

      if (userData.cpf) {
        sapData.quotation.quotationFormBusinessPartner.cnpj = "";
        sapData.quotation.quotationFormBusinessPartner.cpf = userData.cpf;
      }

      if (userData.cnpj) {
        sapData.quotation.quotationFormBusinessPartner.cpf = "";
        sapData.quotation.quotationFormBusinessPartner.cnpj = userData.cnpj;
      }

      if (userData.email) {
        sapData.quotation.quotationFormBusinessPartner.email = userData.email;
      }

      if (userData.name) {
        sapData.quotation.quotationFormBusinessPartner.cardName = userData.name;
      }

      if (userData.phone) {
        sapData.quotation.quotationFormBusinessPartner.phone1 = userData.phone;
      }


      let encodedString = `{"url": "${URLS.SAP}/ServOnlinePrice", "metodo": "POST", "headers" : { "requester": "SevenApp" }, "body": ${JSON.stringify(sapData.quotation)}}`;
      encodedString = JSON.stringify(JSON.parse(encodedString),null,4);

      const utf8Bytes = encodeURIComponent(encodedString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
      });
  
      encodedString = btoa(utf8Bytes);

      const result = await api_keyed.post(`${URLS.URLPROXY}/SAP`, { json: encodedString }).json();
      const dados = JSON.parse(atob(result));

      return dados;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  static async getProducts(uf) {
    try {
      const sapData = Lockr.get('Loovi-SAP');

      sapData.quotation.quotationForm.state = uf;

      let encodedString = `{"url": "${URLS.SAP}/ServOnlinePrice", "metodo": "POST", "headers" : { "requester": "SevenApp" }, "body": ${JSON.stringify(sapData.quotation)}}`;
      encodedString = JSON.stringify(JSON.parse(encodedString),null,4);

      const utf8Bytes = encodeURIComponent(encodedString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
      });
  
      encodedString = btoa(utf8Bytes);

      const result = await api_keyed.post(`${URLS.URLPROXY}/SAP`, { json: encodedString }).json();
      const dados = JSON.parse(atob(result));

      dados.quotation.statePlain.map(plan => plan.selected = false);

      return dados;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  static async setPlan() {
    try {
      const sapData = Lockr.get('Loovi-SAP');
      const hireData = Lockr.get('HireData');

      // Caso venha do Wordpress (rota: /contratar/:sapPlanId/:sapDiscountId)
      if (hireData?.shouldHire) {
        const customerUF = sapData?.quotation?.quotationFormAddressPayment?.state;

        if (!customerUF) {
          alert("Não foi possível completar pedido. Endereço sem UF cadastrado.");
          return false;
        }

        const planName = `${hireData?.sapPlanId}_${customerUF}`;
        const userPlanIndex = sapData.quotation.statePlain.findIndex(plan => plan.idPln === planName);

        if (userPlanIndex === -1) {
          alert(`Não foi possível completar pedido. Plano não encontrado. (${planName})`);
          return false;
        }

        // Limpa todos os planos
        sapData.quotation.statePlain.map(plan => plan.selected = false);

        // Seleciona plano filtrado
        sapData.quotation.statePlain[userPlanIndex].selected = true;

        //Verifica se veio campanha como parametro na URL
        if (hireData.sapDiscountId !== "") {
          const couponId = `${hireData.sapDiscountId}_${customerUF}`;
          
          // Já aproveita para setar o cupom junto
          sapData.quotation.quotationForm.cmpgnID = couponId;

          // Seta cupom no sessionStorage para interface identificar que já tem voucher
          // cupom preenchido
          sessionStorage.setItem('LOOVI_VOUCHER_CODE', couponId);
        }
      } else {
        // Limpa todos os planos
        sapData.quotation.statePlain.map(plan => plan.selected = false);
        // Caso não venha do wordpress, continua com rota normal selecionando o plano padrão.
        sapData.quotation.statePlain[0].selected = true;
      }


      let encodedString = `{"url": "${URLS.SAP}/ServOnlinePrice", "metodo": "POST", "headers" : { "requester": "SevenApp" }, "body": ${JSON.stringify(sapData.quotation)}}`;
      encodedString = JSON.stringify(JSON.parse(encodedString),null,4);

      const utf8Bytes = encodeURIComponent(encodedString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
      });

      encodedString = btoa(utf8Bytes);

      const result = await api_keyed.post(`${URLS.URLPROXY}/SAP`, { json: encodedString }).json();
      const dados = JSON.parse(atob(result));

      Lockr.set('Loovi-SAP', dados);
      return dados;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  static async getFreight(cep) {
    try {
      const sapData = Lockr.get('Loovi-SAP');

      sapData.quotation.quotationForm.zipCode = cep;
      sapData.quotation.quotationFormAddressPayment.zipCode = cep;
      sapData.quotation.quotationFormAddressDelivery.zipCode = cep;

      let encodedString = `{"url": "${URLS.SAP}/ServOnlinePrice", "metodo": "POST", "headers" : { "requester": "SevenApp" }, "body": ${JSON.stringify(sapData.quotation)}}`;
      encodedString = JSON.stringify(JSON.parse(encodedString),null,4);

      const utf8Bytes = encodeURIComponent(encodedString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
      });
  
      encodedString = btoa(utf8Bytes);

      const result = await api_keyed.post(`${URLS.URLPROXY}/SAP`, { json: encodedString }).json();
      const dados = JSON.parse(atob(result));

      return dados;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  static async setAddress(userAddress, shippingAddress) {
    try {
      const sapData = Lockr.get('Loovi-SAP');

      sapData.quotation.quotationFormAddressPayment.streetNo = userAddress.customerStreetNum;
      sapData.quotation.quotationFormAddressDelivery.streetNo = shippingAddress.customerShippingStreetNum;
      sapData.quotation.statePlain = [];
      sapData.quotation.quotationForm.cmpgnID = "";

      let encodedString = `{"url": "${URLS.SAP}/ServOnlinePrice", "metodo": "POST", "headers" : { "requester": "SevenApp" }, "body": ${JSON.stringify(sapData.quotation)}}`;
      encodedString = JSON.stringify(JSON.parse(encodedString),null,4);

      const utf8Bytes = encodeURIComponent(encodedString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
      });
  
      encodedString = btoa(utf8Bytes);

      const result = await api_keyed.post(`${URLS.URLPROXY}/SAP`, { json: encodedString }).json();
      const dados = JSON.parse(atob(result));


      return dados;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  static async setFreight(type) {
    try {
      const sapData = Lockr.get('Loovi-SAP');

      sapData.quotation.quotationFormFreight.forEach(function (value, index) {

        if (sapData.quotation.quotationFormFreight[index].typeDel === type) {
          sapData.quotation.quotationFormFreight[index].selected = true;
        } else {
          sapData.quotation.quotationFormFreight[index].selected = false;
        }
      });

      let encodedString = `{"url": "${URLS.SAP}/ServOnlinePrice", "metodo": "POST", "headers" : { "requester": "SevenApp" }, "body": ${JSON.stringify(sapData.quotation)}}`;
      encodedString = JSON.stringify(JSON.parse(encodedString),null,4);

      const utf8Bytes = encodeURIComponent(encodedString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
      });
  
      encodedString = btoa(utf8Bytes);

      const result = await api_keyed.post(`${URLS.URLPROXY}/SAP`, { json: encodedString }).json();
      const dados = JSON.parse(atob(result));

      return dados;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  static async setCoupon(coupon) {
    try {
      const sapData = Lockr.get('Loovi-SAP');

      sapData.quotation.quotationForm.cmpgnID = coupon;

      let encodedString = `{"url": "${URLS.SAP}/ServOnlinePrice", "metodo": "POST", "headers" : { "requester": "SevenApp" }, "body": ${JSON.stringify(sapData.quotation)}}`;
      encodedString = JSON.stringify(JSON.parse(encodedString),null,4);

      const utf8Bytes = encodeURIComponent(encodedString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
      });
  
      encodedString = btoa(utf8Bytes);

      const result = await api_keyed.post(`${URLS.URLPROXY}/SAP`, { json: encodedString }).json();
      const dados = JSON.parse(atob(result));

      return dados;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  static async getSellers() {

    let encodedString = `{"url": "${URLS.SAP}/GetSellers", "metodo": "GET", "headers" : { "requester": "SevenApp" }}`;
    encodedString = JSON.stringify(JSON.parse(encodedString),null,4);

    const utf8Bytes = encodeURIComponent(encodedString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode('0x' + p1);
    });

    encodedString = btoa(utf8Bytes);
  
    try {

      const result = await api_keyed.post(`${URLS.URLPROXY}/SAP`, { json: encodedString }).json();

      const dados = JSON.parse(atob(result));

      return dados;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
