import React from 'react';
import styled from 'styled-components';
import { Hero } from './components/Hero';
import { FaqSection } from './components/faq/FaqSection';
import Footer from './components/Footer';
import Button from './components/Button';

const section1 = [
  {
    title: 'Como eu posso contratar a Loovi?',
    description: `É bem simples! Você vai gastar no máximo 5 minutos. Basta seguir as etapas abaixo:`,
    topics: [
      'Em nosso site, clique em contratar',
      'Informe seus dados e clique em avançar',
      'Informe seu endereço e clique em avançar',
      'Revise os valores e efetue o pagamento. Só lembrando que em nosso checkout só aceitamos cartão de crédito e débito.',
      'Pronto!  Agora é só esperar que o produto será enviado para sua casa.'
    ]
  },
  {
    title: 'Quais são as formas de pagamento?',
    description: 'Atualmente só aceitamos cartões de crédito e débito como forma de pagamento. Utilizamos a plataforma de pagamento mais confiável do mundo. O pagamento é debitado automaticamente todo mês, desta forma, não prejudicamos o limite de compra do seu cartão.'
  },
  {
    title: 'Como é feita a vistoria do veículo?',
    description: 'Após a contratação do plano, a vistoria é feita através do aplicativo. São solicitadas fotos do veículo incluindo imagens do chassi, motor, placa, entre outras. Assim agilizamos o processo de cadastro do veículo de forma inteligente.'
  },
  {
    title: 'Como contratar serviços adicionais ou fazer um upgrade do meu plano?',
    description: 'Basta entrar em contato por e-mail ou pelo telefone da Assistência ao Cliente 24h (0800 948 4888).'
  },
  {
    title: 'Motoristas particulares e taxistas podem contratar?',
    description: 'Sim! Se você for motorista autônomo, basta efetuar a compra diretamente pelo nosso site. Se quiser o plano da Loovi para uma frota, ligue em nossa central de atendimento: 0800 944 7000.'
  }
];

const section2 = [
  {
    title: 'Qual plano é oferecido pela Loovi?',
    description: 'Hoje a Loovi trabalha com um único plano que inclui:',
    topics: [
      'Garantia contra furto ou roubo 100% FIPE até o valor máximo de R$ 60.000,00',
      'Rastreamento do veículo 24h',
      'Aplicativo para acompanhamento do veículo em tempo real, que vem com diversas funcionalidades: Histórico de Rotas e Alerta de movimento.',
      'Dispositivo Loovi OBD2 para conexão no carro',
      'Chip de dados para comunicação do veículo com o aplicativo',
      'Dual GPS'
    ]
  },
  {
    title: 'Posso contratar o Assistência 24h?',
    description: 'Sim, para contratar o Assistência 24h é necessário ter um plano ativo de Garantia Inteligente. Para contratar, basta ligar em nossa central de atendimento.'
  },
  {
    title: 'Quais os serviços oferecidos pela Assistência 24h?',
    description: '',
    withSubtopics: [
      {
        topic: 'REBOQUE',
        subtopics: [
          'Reboque após pane seca.',
          'Reboque após pane elétrica.',
          'Reboque após pane mecânica.',
          'Reboque após sinistros/colisões.',
          'Reboque após acidentes.',
          'Reboque após incêndio.',
          'Reboque para reparo em pneu.'
        ],
      },
      {
        topic: 'AUXÍLIO PNEU FURADO',
        subtopics: [
          'Serviço de troca de pneu furado pelo estepe.',
          'Serviços de reparo de pequenas avarias no local.',
        ],
      },
      {
        topic: 'TÁXI OU TRANSPORTE ALTERNATIVO',
        subtopics: [
          'Transporte em caso de acidente.',
          'Transporte em caso de colisão.',
          'Transporte em caso de incêndio.',
          'Transporte em caso de furto.',
          'Transporte em caso de roubo.',
          'Transporte para busca do veículo.',
          'Transporte em caso de pane elétrica.',
          'Transporte em caso de pane mecânica.',
        ],
      },
      {
        topic: 'GUARDA DO VEÍCULO',
        subtopics: [
          'Local reservado para pernoitar e de uso exclusivo do veículo.',
        ],
      },
      {
        topic: 'CHAVEIRO (Contratação opcional)',
        subtopics: [
          'Abertura do veículo.',
          'Confecção de Chave.'
        ],
      },
      {
        topic: 'HOSPEDAGEM',
        subtopics: [
          'Hospedagem em caso de acidente.',
          'Hospedagem em caso de colisão.',
          'Hospedagem em caso de incêndio.',
          'Hospedagem em caso de furto.',
          'Hospedagem em caso de roubo.',
          'Hospedagem para busca do veículo.',
          'Hospedagem em caso de pane elétrica.',
          'Hospedagem em caso de pane mecânica.'
        ],
      },
      {
        topic: 'REEMBOLSO DE SERVIÇOS',
        subtopics: [
          'Guarda de veículo para casos extremos.',
        ],
      },
      {
        topic: 'CARRO RESERVA (Contratação opcional)',
        subtopics: [
          'Carro reserva furto.',
          'Carro reserva incêndio.',
          'Carro reserva alagamento.',
          'Carro reserva colisão.',
          'Carro reserva acidente.'
        ],
      }
    ],
  },
];

const section3 = [
  {
    title: 'O que acontece se roubarem meu carro em outra cidade?',
    description: 'A Loovi conta com uma rede de parceiros especializados na busca e recuperação de veículos. Nossos planos garantem segurança em todo o país e a entrega do veículo é toda intermediada pela Loovi para maior comodidade do cliente.',
  },
  {
    title: 'A Loovi cobre colisão?',
    description: 'Não cobrimos colisão por um motivo simples: Quantas vezes você acionou o seguro do seu carro contra batida? Identificamos que na maioria das colisões o valor do conserto fica mais barato do que acionar a franquia do seguro. Então, para quê pagar por uma coisa que você dificilmente vai usar? Faça as contas você mesmo e descubra a verdade. A Loovi escolheu oferecer um plano inteligente, onde você paga pelo que realmente usa, ao invés de pagar pelo que não usa.',
  },
  {
    title: 'Se roubarem meu carro e tirarem o dispositivo eu perco a garantia?',
    description: 'Não! Você continua garantido. Ainda que o ladrão retire o dispositivo, você continua coberto e, caso não recuperemos o veículo, pagamos 100% da tabela FIPE.',
  },
];

const section4 = [
  {
    title: 'Como a Loovi faz para proteger meu carro?',
    description: 'A Loovi desenvolveu uma tecnologia de ponta que, por meio de um dispositivo exclusivo, fornece informações sobre o seu carro em tempo real. A partir do momento da conexão do nosso dispositivo, seu veículo é monitorado pela nossa central 24h por dia.',
  },
  {
    title: 'Por que a tecnologia da Loovi é a mais avançada do mundo?',
    description: 'A Loovi utiliza um equipamento de ponta, capaz de fazer leitura de vários dados do carro, desde os mais simples, como trajetos até informações sobre injeção do carro, nível de bateria, temperatura, entre outros. Assim podemos ter um diagnóstico completo do veículo à distância e sem precisar abrir o capô. Também contamos com duas formas para localizar seu veículo, a primeira é através da antena GPS. A segunda é através da triangulação da rede de dados.',
  },
  {
    title: 'Consigo saber se o meu carro está conectado com a Loovi?',
    description: 'Sim, é muito simples. É só acessar a Área do Cliente no site e, através do aplicativo, é possível visualizar a localização do veículo, dessa forma você está conectado.',
  },
];

const section5 = [
  {
    title: 'A Loovi é algum tipo de seguradora?',
    description: 'Não somos seguradora e nem cooperativa de proteção veicular. A Loovi é a primeira empresa de Garantia Inteligente contra furto ou roubo. Por meio da tecnologia eliminamos a burocracia e, assim, conseguimos oferecer soluções mais inteligentes, com mais vantagens e também com preços mais justos.',
  },
  {
    title: 'O que a Loovi faz?',
    description: 'A Loovi é uma empresa de Garantia Inteligente contra furto ou roubo que usa tecnologia para garantir a segurança dos carros e a tranquilidade dos motoristas. Por meio de um sistema inovador, conseguimos oferecer proteção e mais vantagens. Sempre de um jeito humano, inovador e com um preço justo.',
  },
  {
    title: 'A Loovi é uma associação?',
    description: 'Não. A Loovi não é uma associação de proteção veicular. Todos os nossos recursos são de capital próprio. Portanto, não existe rateio e você tem muito mais segurança e certeza de que vai receber o valor equivalente ao seu carro, caso você seja vítima de furto ou roubo.',
  },
];

const section6 = [
  {
    title: 'A Loovi tem fidelização?',
    description: 'Não dentro dos primeiros 30 dias, que é o período que damos para os nossos clientes terem a certeza de que fizeram a escolha certa. Após os 30 dias, o plano anual é ativado e a fidelidade se inicia pelos próximos 12 meses.',
  },
  {
    title: 'Onde posso ver o meu contrato?',
    description: 'O contrato fica disponível na Área do Cliente. É só acessar inserindo o número de CPF ou CNPJ utilizado no cadastro e aguardar o recebimento do token por SMS ou email.',
  },
  {
    title: 'A Loovi tem análise de perfil?',
    description: 'Não. O que importa de verdade é garantir o seu carro e a sua tranquilidade. Também não exigimos indicação de condutor, ou seja, seu carro está protegido mesmo se outra pessoa estiver dirigindo.',
  },
  {
    title: 'Como faço para cancelar o meu plano?',
    description: 'Para cancelar o plano, você só precisa ligar no 0800 944 7000 e solicitar o cancelamento imediato. O time de relacionamento irá orientá-lo sobre como efetuar a devolução do dispositivo via correios.',
  },
  {
    title: 'Depois de efetuar o pagamento, quando meu plano passa a valer?',
    description: 'O serviço de garantia contra furto e roubo só será ativado quando você plugar o dispositivo no seu veículo, enviar as fotos solicitadas pelo aplicativo e estas forem aprovadas em nosso sistema. No caso da Assistência 24h, existe o prazo de carência de 30 dias corridos para utilizar os serviços, conforme previsto em contrato.',
  },
  {
    title: 'O que acontece se eu ficar inadimplente?',
    description: 'Caso o pagamento não seja efetuado no dia do vencimento (até às 23:59 do mesmo dia) os serviços serão automaticamente suspensos.',
  },
  {
    title: 'Sou cliente da Loovi e vou trocar de carro. Como devo proceder?',
    description: 'É preciso entrar em contato com a Loovi. Iremos resetar o cadastro do seu veículo e um novo processo de instalação será iniciado.',
  },
];

const section7 = [
  {
    title: 'Como acessar?',
    description: 'Basta inserir o CPF ou CNPJ utilizado na compra que um código será enviado por SMS no número de telefone cadastrado ou por email. Após receber o código, basta utilizá-lo para acessar a Área do Cliente.',
  },
  {
    title: 'Como funciona a função Alerta de Movimento?',
    description: 'É bem simples. Ao estacionar o carro, abra o aplicativo e aperte o botão Alerta de Movimento. A partir disso, se o carro movimentar, nossa central é acionada e você recebe um alerta no celular. Ao entrar no carro novamente é só repetir o procedimento para desativar a função.',
  },
  {
    title: 'Meu carro deu problema. Como vou chamar a assistência 24h?',
    description: 'A Loovi resolve. É só ligar para gente utilizando o botão Assistência 24h do seu aplicativo. Enviamos uma equipe para atender você.',
  },
  {
    title: 'Quero ver as parcelas que já paguei. Onde encontro essa informação?',
    description: 'Acesse a Área do Cliente no aplicativo ou no site. Depois, clique em Minha Conta e, logo em seguida, clique na opção Pagamento.',
  },
];

export const FAQ = () => {
  function fireTracker() {
    window._etmc.push(["setOrgId", "110005701"]);
    window._etmc.push(["trackPageView", { "item": "LOOVI_PLANO_001" }]);
  }

  return (
    <>
      <Container>
        <Hero heroContext="faq" />
        <FaqSection
          title="Como Contratar"
          questions={section1}
        />
        <FaqSection
          title="Planos e Serviços"
          questions={section2}
        />
        {!isMobile() && <BlueCircle />}
        <FaqSection
          title="Sinistro"
          questions={section3}
        />

        <FaqSection
          title="Como Funciona"
          questions={section4}
        />

        <FaqSection
          title="O que é a Loovi"
          questions={section5}
        />
        {!isMobile() && <BigGreenCircle />}
        <FaqSection
          title="Contrato e Pagamento"
          questions={section6}
        />
        <FaqSection
          title="Área do Cliente"
          questions={section7}
        />
        <RingGreenCircle />
        {!isMobile() ? (
          <>
            <LineContainer>
              <TopTinyBlueCircle />
              <FirstVerticalLine />
              <DiagonalLine />
              <SecondVerticalLine />
              <BottomTinyBlueCircle />
            </LineContainer>
            <BottomTitle>Não é seguro e nem associação</BottomTitle>
            <BottomDescription>É garantia inteligente para o seu carro.</BottomDescription>
            <ButtonContainer>
              <Button primary href="/checkout" onClick={fireTracker}>Contrate agora</Button>
            </ButtonContainer>
          </>
        ) : (
            <>
              <LineMobileContainer>
                <LeftTinyCircle />
                <FirstHorizontalLine />
                <MobileDiagonalLine />
                <SecondHorizontalLine />
                <RightTinyCircle />
              </LineMobileContainer>
              <BottomTitle>Não é seguro e <br /> nem associação</BottomTitle>
              <BottomDescription>É garantia inteligente para o seu carro.</BottomDescription>
              <ButtonContainer>
                <Button primary href="/checkout" onClick={fireTracker}>Contrate agora</Button>
              </ButtonContainer>
            </>

          )}

      </Container>
      <Footer />
    </>
  );
}

const Container = styled.div`
  overflow: hidden;
  padding-bottom: 140px;
  position: relative;

  @media (max-width: 768px){
    padding-bottom: 55px;
  }
`;

const BlueCircle = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #5A78FF;
    position: absolute;
    z-index: 0;
    left: -130px;


    @media (max-width: 768px){
        left: -175px;
    }
`;

const BigGreenCircle = styled.div`
    right: -300px;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background-color: #57D9A3;
    position: absolute;
    z-index: 1;

    @media (max-width: 768px){
        display: none;
    }
`;

const RingGreenCircle = styled.div`
    width: 247px;
    height: 247px;
    border-radius: 50%;
    border: 26px solid #57D9A3;
    position: absolute;
    left: -160px;
    z-index: 1;

    @media (max-width: 768px){
      display: none;
    }
`;

const LineContainer = styled.div`
  position: relative;
  width: 300px;
  height: 600px;
  margin: 0 auto;
  transform: rotate(180deg);

  @media (max-width: 768px){
    margin: 0 17%;
    }
`;

const LineMobileContainer = styled(Container)`
  position: relative;
  width: 300px;
  height: 145px;
  margin: 0 auto;
`;

const TopTinyBlueCircle = styled.div`
    width: 30px;
    height: 30px;
    left: 150px;
    border-radius: 50%;
    border: 5px solid #5A78FF;
    position: absolute;
    z-index: 1;
`;

const LeftTinyCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid #5A78FF;
  position: absolute;
  z-index: 1;
  bottom: 20px;
  left: 20px;
`;

const RightTinyCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid #5A78FF;
  position: absolute;
  z-index: 1;
  bottom: 75px;
  right: 20px;
`;

const FirstVerticalLine = styled.div`
    border-left: 5px solid #5A78FF;
    height: 163px;
    position: absolute;
    left: 162px;
    margin-top: top;
    top: 25px;
`;

const FirstHorizontalLine = styled.div`
  border-top: 5px solid #5A78FF;
  width: 79px;
  position: absolute;
  bottom: 27px;
  left: 38px;
`;

const SecondHorizontalLine = styled.div`
  border-top: 5px solid #5A78FF;
  width: 80px;
  position: absolute;
  bottom: 83px;
  right: 39px;
`;

const DiagonalLine = styled.div`
    border-left: 5px solid #5A78FF;
    height: 151px;
    position: absolute;
    left: 210px;
    top: 168px;
    -webkit-transform: rotate(-25deg);
    -ms-transform: rotate(-25deg);
    -webkit-transform: rotate(-25deg);
    -ms-transform: rotate(-25deg);
    transform: rotate(-40deg);
`;

const MobileDiagonalLine = styled.div`
  border-top: 5px solid #5A78FF;
  width: 90px;
  position: absolute;
  -webkit-transform: rotate(-40deg);
  -ms-transform: rotate(-40deg);
  -webkit-transform: rotate(-40deg);
  -ms-transform: rotate(-40deg);
  transform: rotate(-40deg);
  bottom: 55px;
  left: 104px;
`;

const SecondVerticalLine = styled(FirstVerticalLine)`
  left: 259px;
  top: 300px;
`;

const BottomTinyBlueCircle = styled(TopTinyBlueCircle)`
  left: 246px;
    top: 460px;
`;

const BottomTitle = styled.div`
  color: #5A78FF;
  font-weight: bold;
  text-align: center;
  font-size: 50px;
  margin-top: 100px;
  padding: 15px;

  @media (max-width: 768px){
    font-size: 30px;
    margin-top: 20px;
  }
`;

const BottomDescription = styled.div`
  color: #5A78FF;
  font-size: 20px;
  text-align: center;
  font-family: 'BrownStd-Bold';
  padding: 30px;

  @media (max-width: 768px){
    font-size: 18px;
    padding: 20px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;

  @media (max-width: 768px){
    margin-top: 10px;
  }
`;

function isMobile() {
  return window.innerWidth < 768;
}

var currentLocation = window.location.pathname;
if (currentLocation === '/duvidas-frequentes') {
  document.body.style.padding = '0'
}
