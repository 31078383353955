const { URLS, api, USER_SAP } = require("./Request");

export async function authSAP() {
  try {
    const result = await api.get(`${URLS.SAP.replace('/Loovi', '/Sap')}/GetToken`, {
      headers: {
        'Authorization': `Basic ${btoa(`${USER_SAP}`)}`
      }
    }).json();

    if (result.error) {
      alert(1);
      throw new Error(result.error);
    }

    window['LOOVI_SAP_TOKEN'] = result.token;

  } catch(e) {
    alert('Não foi possível comunicar com nossos servidores. Por favor, tente novamente. (0x_2392493_authSAP)');
    console.log(e);
  }
}
