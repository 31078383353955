// Dependencias

import React from 'react'
import styled from 'styled-components'

// Imagens

import looviIconImage from '../assets/images/loovi-logo-icon.png'

const Header = (props) => (
    <Section background={props.background} padding={props.padding} logo={props.logo}>
        {props.logo && <figure className="loovi-icon"><img src={looviIconImage} alt="Loovi"/></figure>}
        {props.children}
    </Section>
)

const Section = styled.section`
    text-align: center;
    position: relative;
    z-index: 2;
    background: ${props => props.background ? props.background : "#FFFFFF" };
    padding: ${props => props.padding ? "30px 0" : "0" };

    figure{

        margin: auto 0;

        img{

            max-width: 63px;
        }
    }
    
    h1{

        color: #FFFFFF;
        font-size: 26px !important;
        margin: 0;
    }

    h2{

        font-size: 24px !important;
        color: #FFFFFF;
        letter-spacing: -0.86px;
        text-align: center;
        line-height: 30px;
        margin: 25px 0 0 0;
    }

    p{

        color: #FFFFFF;
        font-size: 15px;
        margin: 0;
    }
`

export default Header