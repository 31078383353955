import React from 'react';
import styled from 'styled-components';
import Barcode from 'react-barcode';

function Voucher({ code }) {
  return (
    isMobile() ? (
      <MobileContainer>
        <TopSideContainer>
          <LeftTopCornerCircle></LeftTopCornerCircle>
          <RightTopCornerCircle></RightTopCornerCircle>
          <VoucherLabel>VOUCHER</VoucherLabel>
          <TopMiddleCircle top="50px" />
          <TopMiddleCircle top="90px" />
          <TopMiddleCircle top="130px" />
          <TopMiddleCircle top="170px" />
          <TopMiddleCircle top="210px" />
        </TopSideContainer>
        <DiscountContainerMobile>
          <PercentageLabelMobile>100</PercentageLabelMobile>
          <PercentageSymbol>%</PercentageSymbol>
          <DiscountLabelMobile>de desconto</DiscountLabelMobile>
        </DiscountContainerMobile>
        <MobileInfoContainer>
          <TotalLabelMobile>Frete Grátis</TotalLabelMobile>
        </MobileInfoContainer>
        <BottomSideContainer>
          <CodeLabelMobile><strong>CÓDIGO: {code}</strong></CodeLabelMobile>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Barcode displayValue={false} flat height="50" value={code} />
          </div>
          <LeftMobileBottomCornerCircle></LeftMobileBottomCornerCircle>
          <RightMobileBottomCornerCircle></RightMobileBottomCornerCircle>
          <BottomMiddleCircle top="50px" />
          <BottomMiddleCircle top="90px" />
          <BottomMiddleCircle top="130px" />
          <BottomMiddleCircle top="170px" />
          <BottomMiddleCircle top="210px" />
        </BottomSideContainer>
      </MobileContainer>
    ) : (
        <Container>
          <LeftSideContainer>
            <LeftTopCornerCircle />
            <LeftMiddleCircle top="55px" />
            <LeftMiddleCircle top="95px" />
            <LeftMiddleCircle top="130px" />
            <LeftMiddleCircle top="165px" />
            <LeftMiddleCircle top="200px" />
            <LeftLabel>VOUCHER</LeftLabel>
            <LeftBottomCornerCircle />
          </LeftSideContainer>
          <ContentContainer>
            <TopContainer>
              <DiscountContainer>
                <PercentageLabel>100%</PercentageLabel>
                <DiscountLabel>de desconto</DiscountLabel>
              </DiscountContainer>
              <RightInfoContainer>
                <TotalLabel>Frete Grátis</TotalLabel>
              </RightInfoContainer>
            </TopContainer>
            <BottomContainer>
              <CodeLabel>CÓDIGO: {code}</CodeLabel>
              <Barcode displayValue={false} flat height="50" value={code} />
            </BottomContainer>
          </ContentContainer>
          <RightSideContainer>
            <RightBottomCornerCircle />
            <RightMiddleCircle top="55px" />
            <RightMiddleCircle top="80px" />
            <RightMiddleCircle top="115px" />
            <RightMiddleCircle top="150px" />
            <RightMiddleCircle top="185px" />
            <RightLabel>VOUCHER</RightLabel>
            <RightTopCornerCircle />
          </RightSideContainer>
        </Container>
      )
  );
}

const PercentageSymbol = styled.div`
  position: absolute;
  font-size: 40px;
  font-weight: bolder;
  right: 48px;
  top: 18px;
`;

const TopMiddleCircle = styled.div`
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: #5A77FF;
  position: absolute;
  left: ${props => props.top};
  top: -10px;
`;

const BottomMiddleCircle = styled.div`
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: #5A77FF;
  position: absolute;
  left: ${props => props.top};
  bottom: -10px;
`;

const VoucherLabel = styled.div`
  letter-spacing: 5px;
  font-size: 25px;
  left: 64px;
  top: 15px;
  position: absolute;
`;

const MobileContainer = styled.div`
  width: 285px;
  margin: 0 auto;
  margin-top: 30px;
  height: 280px;
  background-color: #ffffff;
`;

const TopSideContainer = styled.div`
  position: relative;
  height: 60px;
  background-color: #ffffff;
  border-bottom: 2px dashed #3a3a3a;
`;

const BottomSideContainer = styled.div`
  height: 170px;
  position: relative;
  background-color: #ffffff;
  border-top: 2px dashed #3a3a3a;
`;

const Container = styled.div`
  width: 700px;
  height: 280px;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
`;

const LeftSideContainer = styled.div`
  position: relative;
  width: 60px;
  height: 100%;
  background-color: #ffffff;
  border-right: 2px dashed #3a3a3a;
`;

const LeftLabel = styled.div`
  font-size: 30px;
  transform: rotate(-90deg);
  font-size: 30px;
  position: absolute;
  top: 110px;
  left: -55px;
  letter-spacing: 5px;
`;

const LeftBottomCornerCircle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #5A77FF;
  position: absolute;
  bottom: -35px;
  left: -35px;
`;

const LeftMiddleCircle = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #5A77FF;
  position: absolute;
  top: ${props => props.top};
  left: -13px;
`;

const LeftTopCornerCircle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #5A77FF;
  position: absolute;
  top: -35px;
  left: -35px;
`;

const LeftMobileBottomCornerCircle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #5A77FF;
  position: absolute;
  bottom: -35px;
  left: -35px;
`;

const RightSideContainer = styled(LeftSideContainer)`
  position: relative;
  width: 60px;
  height: 100%;
  border-right: 2px dashed #3a3a3a;
  background-color: #ffffff;
  border-right: none;
  border-left: 2px dashed #3a3a3a;
`;

const RightLabel = styled.div`
  font-size: 30px;
  transform: rotate(90deg);
  font-size: 30px;
  position: absolute;
  top: 110px;
  right: -55px;
  letter-spacing: 5px;
`;

const RightBottomCornerCircle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #5A77FF;
  position: absolute;
  bottom: -35px;
  right: -35px;
`;

const RightMiddleCircle = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #5A77FF;
  position: absolute;
  top: ${props => props.top};
  right: -13px;
`;

const RightTopCornerCircle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #5A77FF;
  position: absolute;
  top: -35px;
  right: -35px;
`;

const RightMobileBottomCornerCircle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #5A77FF;
  position: absolute;
  bottom: -35px;
  right: -35px;
`;

const ContentContainer = styled.div`
  width: 580px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 170px;
`;

const DiscountContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 260px;
  border-right: 2px dashed #3a3a3a;
`;

const DiscountContainerMobile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 2px dashed #3a3a3a;
  height: 129px;
`;

const PercentageLabel = styled.div`
  font-size: 80px;
  font-weight: 1000;
`;


const PercentageLabelMobile = styled.div`
  font-size: 80px;
  font-weight: 1000;
  position: absolute;
  left: 67px;
`;

const DiscountLabel = styled.div`
  font-size: 20px;
  position: absolute;
  top: 110px;
  left: 37px;
`;

const DiscountLabelMobile = styled.div`
  font-size: 20px;
  position: absolute;
  top: 89px;
  left: 77px;
`;

const RightInfoContainer = styled.div`
  width: 320px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MobileInfoContainer = styled.div`
background-color: #ffffff;
  padding-left: 25px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TotalLabel = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
`;

const TotalLabelMobile = styled.div`
  font-size: 22px;
`;

const BottomContainer = styled.div`
  border-top: 2px dashed #3a3a3a;
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;

const CodeLabel = styled.div`
  font-size: 30px;
  color: #3a3a3a;
`;

const CodeLabelMobile = styled.div`
  font-size: 30px;
  margin-top: 20px;
  color: #3a3a3a;
  text-align: center;
`;

function isMobile() {
  return window.innerWidth < 768;
}

export default Voucher;
