import styled from 'styled-components';

export const Link = styled.a`
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1.22px;

    color: ${props => props.primary ? "#57D9A3" : "#5A78FF"};

    &:hover{

        color: ${props => props.primary ? "#5A78FF" : "#57D9A3"};
    }
`;

export const Title = styled.h1`
    color: ${props => props.fontColor}
`;