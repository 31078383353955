import styled, { css } from 'styled-components'

const Button = styled.a`
    background: #ffffff;
    display: inline-block;
    padding: 15px 35px;
    border-radius: 4px;
    font-size: 13px;
    color: #393043;
    letter-spacing: 1.22px;
    text-align: center;
    text-transform: uppercase;

    &:hover{

        color: #FFFFFF;
        background: #57D9A3;
    }

    ${props => props.primary && css`
        background: #57D9A3;

        &:hover{

            color: #FFFFFF;
            background: #393043;
        }
    `}

    @media (max-width: 768px){

        span{

            display: none;
        }
    }
`

export default Button