// Dependencias

import React from 'react'
import styled from 'styled-components'
import { Row, Col, Container } from 'react-bootstrap'
import { BrowserView, MobileView } from "react-device-detect"
import { version } from '../../package.json';

// Componentes

import { Logo } from '../components/Logo'
import { StoreIcons } from '../components/StoreIcons'
import { SocialIcons } from '../components/SocialIcons'

// Imagens

import compraSeguraImage from '../assets/images/compra-segura.png'
import iconAreaCliente from '../assets/images/icon-area-cliente.png'

export const Footer = () => (
    <Section>
        <Container>
            <Row>
                <Col md={12} lg={3}>
                    <BrowserView>
                        <Logo href="/" />
<p className="loovi-desc loovi-small-font">Não temos nenhum receio de desafiar o sistema tradicional das grandes seguradoras. Acreditamos que somente com tecnologia e muita inovação  é possível oferecer produtos e serviços melhores para nossos clientes.<br /><br /><span style={{opacity: 0.3}}>Site v{version}</span></p>
                    </BrowserView>
                    <MobileView>
                        <a className="loovi-area-cliente" href="/painel"><i></i> Área do cliente</a>
                    </MobileView>
                </Col>
                <Col md={12} lg={3}>
                    <div className="loovi-footer-nav">
                        <h5 className="loovi-uppercase">Navegue</h5>
                        <nav>
                            {/*  <a href="https://loovi.com.br">Sobre Nós</a> */}
                            {/* <a href="https://loovi.com.br">Dúvidas Frequentes</a> */}
                            {/*                             <a href="https://loovi.com.br">Plano de Indicação</a> */}
                            <a href="/duvidas-frequentes">Dúvidas Frequentes</a>
                            <a href="/contato">Contato</a>
                            <a href="/indicacao">Mensalidade Zero</a>
                           
                        </nav>
                    </div>
                </Col>
                <Col md={12} lg={3}>
                    <div className="loovi-footer-phones">
                        <h5 className="loovi-uppercase">Televendas</h5>
                        <h4 className="loovi-color-0">0800 944 7000</h4>
                        <span className="loovi-spacer" />

                        <h5 className="loovi-uppercase">Furto ou roubo</h5>
                        <h4 className="loovi-color-0">0800 607 2007</h4>
                        <span className="loovi-spacer" />

                        <h5 className="loovi-uppercase">Assistência 24h</h5>
                        <h4 className="loovi-color-0">0800 948 4888</h4>
                    </div>
                </Col>
                <Col md={12} lg={3}>
                    <div className="loovi-footer-app">
                        <h5 className="loovi-uppercase">Baixe o aplicativo</h5>
                        <StoreIcons />
                        <span className="loovi-spacer" />
                        <h5 className="loovi-uppercase">Nossas redes</h5>
                        <SocialIcons />
                    </div>
                </Col>
            </Row>
            <span className="loovi-spacer" />
            <span className="loovi-spacer" />
            <span className="loovi-spacer" />
            <Row className="loovi-footer-credits">
                <Col md={12} lg={3}><img className="compra-segura" src={compraSeguraImage} alt="Compra Segura" /></Col>
                <Col md={12} lg={3} className="d-flex align-items-center"><p className="loovi-small-text" >Copyright © 2020</p></Col>
                <Col md={12} lg={3} className="d-flex align-items-center"><a target="_blank" rel="noopener noreferrer" className="loovi-small-text" href='https://contratos-clientes.s3.amazonaws.com/politica-de-privacidade-loovi.pdf'>Política de Privacidade</a></Col>
                <Col md={12} lg={3} className="d-flex align-items-center"><a target="_blank" rel="noopener noreferrer" className="loovi-small-text" href='https://contratos-clientes.s3.amazonaws.com/termos-de-uso-loovi.pdf'>Termos de Uso</a></Col>
            </Row>
        </Container>
    </Section>
)

const Section = styled.div`
    position: relative;
    padding: 100px 0 60px 0;
    color: #FFFFFF;
    background: #393043;
    color: #FFFF;

    .loovi-desc{

        margin-right: 30px;
    }

    a{

        color: #FFFFFF;
        transition: .4s;

        &:hover{

            color: #57D9A3;
        }
    }

    p{

        margin: 0;
    }

    h5{

        opacity: .6;
    }

    h4{

        font-size: 18px;
    }

    .compra-segura{

        max-width:177px;
        height: auto;
    }

    nav{

        a{
            color:#FFFFFF;
            font-size: 15px;
            display: block;
            padding: 15px 0;
        }
    }

    .loovi-area-cliente{

        color: #FFFFFF;
        display: block;
        text-transform: uppercase;
        font-size: 13px;
        margin-bottom: 60px;

        i{

            display: inline-block;
            width: 50px;
            height: 50px;
            background: #FFFFFF;
            border-radius: 50%;
            background-image: url(${iconAreaCliente});
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px 20px;
            margin-right: 15px;
            vertical-align: middle;
        }
    }

    @media (max-width:768px){

        text-align: center;

        .loovi-footer-nav,
        .loovi-footer-phones{

            margin-bottom: 60px;
        }

        .loovi-footer-credits{

            div{

                display: block !important;
                text-align: center;
                margin-bottom: 30px;
            }
        }

    }
`

export default Footer
