// Dependencias

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Lockr from 'lockr';

// Componentes

import { Container, Row, Col } from 'react-bootstrap'
import Header from '../Header'
import Body from '../Body'
import FormContactData from './FormContactData'
import LoaderAnimation from '../../components/LoaderAnimation'
import SapService from '../../services/SapService'

function ContactData(props) {
  const { values, handleChange } = props;
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   //Event snippet for [CC] Adicionou ao carrinho conversion page 
    
  //   window.gtag('event', 'conversion', {
  //     'send_to': 'AW-851444546/9tfjCP-HkoIYEMKGgJYD',
  //     'value': 1.0,
  //     'currency': 'BRL'
  //   });
  // }, [])

  async function proceed() {
    let result;
    await setIsLoading(true);

    let sapData = Lockr.get('Loovi-SAP');
    sapData = sapData || {};

    if (!sapData.quotation) {
      result = await SapService.initSell();
      if (!result) {
        alert('Erro de Servidor. Por favor, tente novamente. (0x0003_SAP_initSell)');
        await setIsLoading(false);
        return;
      }

      Lockr.set('Loovi-SAP', {
        ...result
      });
    } else if (Math.floor( new Date() - new Date(sapData.quotation.quotationForm.docDate) > (24 * 60 * 60 * 1000))) {
      result = await SapService.initSell();
      if (!result) {
        alert('Erro de Servidor. Por favor, tente novamente. (0x0003_SAP_initSell)');
        await setIsLoading(false);
        return;
      }

      Lockr.set('Loovi-SAP', {
        ...result
      });
    }

    // window.gtag('event', 'conversion', {
    //   'send_to': 'AW-851444546/Taj-CIeWlIIYEMKGgJYD',
    //   'value': 1.0,
    //   'currency': 'BRL'
    // });

    await setIsLoading(false);

    props.nextStep();
  }

  return (
    <Section>
      <Header background="#5A78FF" padding logo />
      <Container>
        <Row>
          <Col sm={12} md={{ span: 4, offset: 4 }}>
            <Body>
              <h2>Inicie seu cadastro</h2>
              <h4>É só um minutinho</h4>
              <span className="loovi-spacer" />
              <FormContactData
                values={values}
                handleChange={handleChange}
                proceed={proceed} />
            </Body>
          </Col>
        </Row>
      </Container>
      <LoaderAnimation show={isLoading} />
    </Section>
  );
}

const Section = styled.section`
    position: relative;

    h2{

        color: "#5A78FF";
        font-size: 28px !important;
        color: #5A78FF;
        letter-spacing: -1px;
        text-align: center;
        line-height: 30px;
        margin: 0;
    }

    h4{

        font-size: 21px !important;
        color: #899299;
        letter-spacing: -0.67px;
        text-align: center;
        line-height: 28px;
        font-family: 'BrownStd-Regular';
    }

    .loovi-radio-group{

        label{

            font-size: 15px;
            color: #899299;
            display: inline-block;
            text-transform: none;
            width: auto;
            margin-right: 15px;

            input{

                display: inline-block;
                vertical-align: middle;
            }

            span{

                text-transform: none;
                display: inline-block;
                vertical-align: middle;
                font-size: 15px;
                margin: 0;
                font-family: 'BrownStd-Regular';
            }

            .checkmark{

                background: #EFF2F7;
            }
        }
    }

    .loovi-radio-disabled span{

        text-decoration: line-through;
    }
`

export default ContactData
