// Dependencias

import React from 'react';
import useForm from 'react-hook-form';
import Lockr from 'lockr';
import * as masks from '../../helpers/masks'


// Componentes

import { URLS } from '../../helpers/Request';
import { updateDataLayer } from '../../helpers/DataLayer';

export const Form = (props) => {

    const values = props.values
    const { register, handleSubmit, errors, clearError, setError, setValue } = useForm()

    function fireWebToLead(data) {
        let form;
        
        if (process.env.REACT_APP_BASE_ENVIRONMENT === 'production') {
            form = {
              'oid': '00D4P0000010vdU',
              'retURL': 'https://loovi.com.br/',

              'first_name': data.customerName.split(' ')[0],
              'last_name': data.customerName.split(' ').slice(1).join(' '),
              'email': data.customerEmail,
              'phone': data.customerPhoneNumber,
              'lead_source': 'Site Forms',
              '00N4P00000DLrb0': 'Site Forms',
              '00N6f00000FeUGF': 'Dados Iniciais',
              'submit': 'Submit',
          }
        } else {
            form = {
                'oid': '00DS0000003A4nP',
                'retURL': 'https://loovi.com.br/',
                'first_name': data.customerName.split(' ')[0],
                'last_name': data.customerName.split(' ').slice(1).join(' '),
                'email': data.customerEmail,
                'phone': data.customerPhoneNumber,
                'lead_source': 'Site Forms',
                '00N4P00000DLrb0': 'Site Forms',
                '00N4P00000FIE11': 'Dados Iniciais',
                'submit': 'Submit',
            }
        }

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');

        fetch(`${URLS.WEBTOLEAD}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(form)
        })
            .then((res) => {
                console.log('WEB TO LEAD ENVIADO COM SUCESSO!');
            })
            .catch((err) => {
                console.log('ERRO AO ENVIAR WEB TO LEAD.');
                console.log(err);
            })
    }

    const onSubmit = data => {
        fireWebToLead(data);
        updateDataLayer({
            "status_da_compra": "USUARIO_PREENCHEU_CONTATO",
            ...data,
        });
        Lockr.set('loovi-metrics', data);
        window._etmc.push(["setOrgId", "110005701"]);
        window._etmc.push(["setUserInfo", {
            "email": data.customerEmail,
        }]);
        window._etmc.push(["trackPageView"]);


        props.proceed(); 

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label>
                <span>Nome completo</span>
                <input
                    name="customerName"
                    defaultValue={values.customerName ? values.customerName : ''}
                    onChange={props.handleChange}
                    ref={register({
                        required: "Preencha o campo acima",
                        minLength: {
                            value: 3,
                            message: "Preencha com no mínimo 3 caracteres"
                        }
                    })}
                    className={errors.customerName ? 'loovi-field loovi-field-error' : 'loovi-field'}
                    type="text" />
            </label>
            {errors.customerName && <span className="loovi-msg-error">{errors.customerName.message}</span>}

            <label>
                <span>Email</span>
                <input
                    name="customerEmail"
                    defaultValue={values.customerEmail ? values.customerEmail : ''}
                    onChange={props.handleChange}
                    ref={register({
                        required: 'Preencha o campo acima',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z.-]+\.[A-Z]{2,4}$/i,
                            message: 'Email inválido'
                        }
                    })}
                    className={errors.customerEmail ? 'loovi-field loovi-field-error' : 'loovi-field'}
                    type="text" />
                {errors.customerEmail && <span className="loovi-msg-error">{errors.customerEmail.message}</span>}
            </label>
            <label>
                <span>Telefone</span>
                <input
                    className={errors.customerPhoneNumber ? 'loovi-field loovi-field-error' : 'loovi-field'}
                    defaultValue={values.customerPhoneNumber ? values.customerPhoneNumber : ''}
                    name="customerPhoneNumber"
                    onChange={(e) => {
                        let value = e.target.value.replace(/[\D]/g, '');
                        if (parseInt(value[2]) !== 9 || value.length < 11) setError('customerPhoneNumber', 'invalid', 'Número de celular inválido');
                        else clearError('customerPhoneNumber');
                        value = masks.phone(value);
                        setValue('customerPhoneNumber', value)
                        props.handleChange(e)
                    }}
                    ref={register({
                        required: true,
                        validate: (input => {
                            const value = input.replace(/[\D]/g, '');
                            return parseInt(value[2]) === 9 && value.length >= 11
                        })
                    })}
                    type="text"
                />
                {errors.customerPhoneNumber && <span className="loovi-msg-error">Preencha o campo acima com um telefone válido.</span>}
            </label>
            <label><span>Tipo de veículo</span></label>
            <div className="loovi-radio-group">
                <label className="loovi-radio loovi-checkbox-light">
                    <input name="customerVehicle" type="radio" checked="checked" onChange={props.handleChange} />
                    <span className="checkmark"></span> Carro
                </label>
                <label className="loovi-radio loovi-checkbox-light loovi-radio-disabled">
                    <input name="customerVehicle" type="radio" disabled onChange={props.handleChange} />
                    <span className="checkmark"></span> <span>Caminhão</span>
                </label>
                <label className="loovi-radio loovi-checkbox-light loovi-radio-disabled">
                    <input name="customerVehicle" type="radio" disabled onChange={props.handleChange} />
                    <span className="checkmark"></span> <span>Moto</span>
                </label>
            </div>
            <input className="loovi-button" type="submit" value="Avançar" />
        </form>
    )
}

export default Form
