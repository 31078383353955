import React from 'react'
import styled from 'styled-components'
import logoImage from '../assets/images/loovi-logo.png';

export const Logo = (props) => (
    <LogoStyle target={props.target} href={props.href}>
        <figure><img src={logoImage} alt="Loovi"/></figure>
    </LogoStyle>
)

const LogoStyle = styled.a`
    figure{

        max-width: 133px;
        transition: .4s;

        &:hover{

            opacity: .6;
        }

        @media (max-width: 768px){

            max-width: 120px;
        }
    }
`