import Lockr from 'lockr';
import { getIndications } from '../components/painel/services'
import { authHandler } from "../helpers/auth";
import { mountSelectedCarObject } from '../helpers/mountSelectedCarObject';
import { URLS, api_keyed, API_BANCO } from '../helpers/Request';

export default class MagicService {

  static async sendPayment(cotation, cardName, cardNumber, cardMonth, cardYear, cvv, value) {

    let encodedString = `{"url": "${URLS.MAGIC}API_NEWCLIENT", "metodo": "POST", "headers" : { "Content-Type": "application/json" }, "body": {"cotacao": "${cotation}", "dadosDePagamento": {"nomeTitular": "${cardName}", "numeroCartao": "${cardNumber}", "mesExpiracao": "${cardMonth}", "anoExpiracao": "${cardYear}", "cvv": "${cvv}", "valor": "${value}","moeda": "BRL"},"crm": "false", "site": "true"}}`;
    
    encodedString = JSON.stringify(JSON.parse(encodedString),null,4);

    const utf8Bytes = encodeURIComponent(encodedString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode('0x' + p1);
    });


    encodedString = btoa(utf8Bytes);

    try {

      const result = await api_keyed.post(`${URLS.URLPROXY}/MAGIC`, { json: encodedString }).json();
      const dados = JSON.parse(atob(result));

      return dados;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  static async sendPlate(plate, quotation) {

    let encodedString = `{"url": "${URLS.MAGIC}API_EQUIPCONNECT", "metodo": "POST", "headers" : { "Content-Type": "application/json" }, "body": { "cotacao": "${quotation}", "placa": "${plate}", "statusPlaca": "false", "conteudoQRCODE": ""}}`;

    encodedString = JSON.stringify(JSON.parse(encodedString),null,4);

    const utf8Bytes = encodeURIComponent(encodedString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode('0x' + p1);
    });

    encodedString = btoa(utf8Bytes);
  
    try {

      const result = await api_keyed.post(`${URLS.URLPROXY}/MAGIC`, { json: encodedString }).json();
      const dataResult = JSON.parse(atob(result));

      return dataResult;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  static async changeCreditCard({ contract, cardName, cardNumber, cardMonth, cardYear, cvv, value, IdSAP__c }) {

    let encodedString = `{"url": "${URLS.MAGIC}API_NEWCLIENT", "metodo": "POST", "headers" : { "Content-Type": "application/json" }, "body": {"contrato": "${contract}", "valor": "${value}", "IdSAP__c": "${IdSAP__c}", "dadosDoCartao": {"nomeTitular": "${cardName}", "numeroCartao": "${cardNumber}", "mesExpiracao": "${cardMonth}", "anoExpiracao": "${cardYear}", "cvv": "${cvv}"},"crm": "false", "site": "true"}}`;
    encodedString = JSON.stringify(JSON.parse(encodedString),null,4);

    const utf8Bytes = encodeURIComponent(encodedString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode('0x' + p1);
    });

    encodedString = btoa(utf8Bytes);
    
    try {

      const result = await api_keyed.post(`${URLS.URLPROXY}/MAGIC`, { json: encodedString }).json();

      const dataResult = JSON.parse(atob(result));

      const cpfOrCnpj = authHandler.getCPForCNPJ() || ''

      encodedString = `{"url": "${URLS.SAP}/GetClient/${cpfOrCnpj}/${API_BANCO}", "metodo": "GET", "headers" : { "requester": "SevenApp" }}`;
      encodedString = JSON.stringify(JSON.parse(encodedString),null,4);

      const utf8Bytes = encodeURIComponent(encodedString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
      });
  
      encodedString = btoa(utf8Bytes);

      const response = await api_keyed.post(`${URLS.URLPROXY}/SAP`, { json: encodedString }).json();

      const dados = JSON.parse(atob(response));

      //Filtra contratos ativos (não cancelados)
      dados.businessPartner.salesContracts = dados.businessPartner.salesContracts.filter((contract) => {
        return contract.cancelled === false;
      })
      
      const clientData = Lockr.get('ClientData')
      const selectedCar = mountSelectedCarObject(dados.businessPartner.salesContracts)[0]
      const nCtrId = selectedCar.contractInfo.nCtrId
      const contratos = await getIndications(nCtrId)

      const data = {
        ...clientData,
        customerSelectedCar: {
          ...selectedCar,
          contractDiscounts: contratos.activeIndicationCtt,
        },
      }

      Lockr.set('ClientData', data);

      return dataResult;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}