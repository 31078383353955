import React from 'react';
import styled from 'styled-components';
import { Accordion, Card, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const accordionHeaderStyles = {
  cursor: 'pointer',
  display: 'flex',
  border: 'none',
  justifyContent: 'space-between',
  backgroundColor: 'white',
  padding: '15px 15px 15px 0',
  alignItems: 'center',
  fontWeight: '600',
  color: '#393043'
}

export const FaqSection = ({ title, questions, template = 'default' }) => {
  return template === 'indication' ? (
    <Container>
      <Accordion>
        {questions.map((question, index) => (
          <AccordionCard style={{ borderBottom: '1px solid #5A78FF', color: '#5A78FF', fontWeight: 'bold' }} key={index} >
            <Accordion.Toggle style={{ ...accordionHeaderStyles, }} as={Card.Header} eventKey={index}>
              {question.title}
              <FontAwesomeIcon icon={faChevronDown} />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index}>
              <Card.Body style={{ color: '#899299', padding: '5px 25px 20px 0' }}>
                {question.description}<br />
                {question.topics && question.topics.length !== 0 && (
                  <ul>
                    {question.topics.map(topic => (
                      <li>{topic}</li>
                    ))}
                  </ul>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </AccordionCard>
        ))}
      </Accordion>
    </Container>
  ) : (
      <FaqContainer>
        <Title>{title && title}</Title>
        <Accordion>
          {questions.map((question, index) => (
            <AccordionCard style={{ borderBottom: '1px solid black' }} key={index} >
              <Accordion.Toggle style={{ ...accordionHeaderStyles }} as={Card.Header} eventKey={index}>
                {question.title}
                <FontAwesomeIcon icon={faChevronDown} />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={index}>
                <Card.Body style={{ color: '#899299', padding: '5px 25px 20px 0' }}>
                  {question.description}<br />
                  {question.topics && question.topics.length !== 0 && (
                    <ul>
                      {question.topics.map(topic => (
                        <li>{topic}</li>
                      ))}
                    </ul>
                  )}
                  {question.withSubtopics && question.withSubtopics.length !== 0 && (
                    <>
                      {question.withSubtopics.map(subtopic => (
                        <>
                          <span><strong>{subtopic.topic}</strong></span>
                          <ul>
                            {subtopic.subtopics.map(sub => (
                              <li>{sub}</li>
                            ))}
                          </ul>
                        </>
                      ))}
                    </>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </AccordionCard>
          ))}
        </Accordion>
      </FaqContainer>
    );
}

const FaqContainer = styled(Container)`
  margin-top: 100px;
`;

const Title = styled.div`
      color: #5A78FF;
      font-size: 40px;
      margin-bottom: 30px;
      font-weight: bold;

      @media (max-width: 768px){
        font-size: 35px;
      }
`;

const AccordionCard = styled(Card)`
  border: none;
`;