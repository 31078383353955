import React, { useEffect } from 'react';
import Lockr from 'lockr';

export default function HireWithoutCampaign(props) {
  const {sapPlanId} = props.match.params;

  useEffect(() => {
    Lockr.rm('HireData');
    const sapDiscountId =  "";

    Lockr.set('HireData', {
      alreadyUsed: false,
      shouldHire: true,
      sapPlanId,
      sapDiscountId,
    });

    setTimeout(() => {
      window.location.pathname = '/contact';
    }, 60);
  }, [sapPlanId]);

  return (
    <></>
  );
}
