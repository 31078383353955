import React, { useEffect } from 'react';
import Lockr from 'lockr';

export default function Hire(props) {
  const {sapPlanId} = props.match.params;

  useEffect(() => {
    Lockr.rm('HireData');
    const sapDiscountId = window.location.pathname.replace(`/contratar/${sapPlanId}/`, "");

    Lockr.set('HireData', {
      alreadyUsed: false,
      shouldHire: true,
      sapPlanId,
      sapDiscountId,
    });

    setTimeout(() => {
      window.location.pathname = '/contact';
    }, 60);
  }, [sapPlanId]);

  return (
    <></>
  );
}
