// Dependencias

import React from 'react';
import styled from 'styled-components';
import Lockr from 'lockr';

// Componentes

import { Container, Row, Col } from 'react-bootstrap'
import Header from '../Header'
import Body from '../Body'

// Imagens

import checkImage from '../../assets/images/check-parabens.png'
import googlePlayButton from '../../assets/images/google-play-button.png'
import appleStoreButton from '../../assets/images/app-store-button.png'
import progress from '../../assets/images/progress-success.png'
import progress2 from '../../assets/images/progress-success.png'

// Helpers

import { clearCheckoutStore } from '../../helpers/store'
import { updateDataLayer } from '../../helpers/DataLayer';

export class Success extends React.Component {

    proceed = event => {
        event.preventDefault()
        this.props.nextStep()
    }

    componentDidMount() {
        clearCheckoutStore();
        const looviSAP = Lockr.get('Loovi-SAP');
        updateDataLayer({ "status_da_compra": "FINALIZADA_SUCESSO", user_name: looviSAP?.quotation?.quotationFormBusinessPartner?.cardName?.split(' ')?.[0] || "N/A" });
        window._etmc.push(["setOrgId", "110005701"]);
        window._etmc.push(["trackPageView"]);
    }

    render() {
        const { values } = this.props

        let firstName;        

        const looviSAP = Lockr.get('Loovi-SAP');

        if (!!looviSAP.quotation.quotationFormBusinessPartner.cardName) firstName = looviSAP.quotation.quotationFormBusinessPartner.cardName.split(' ')[0];
        else if (!!values.customerName) firstName = values.customerName.split(' ')[0];
        else if (!!Lockr.get('loovi-metrics') && Lockr.get('loovi-metrics').customerName) firstName = Lockr.get('loovi-metrics').customerName.split(' ')[0];
        else firstName = 'e bem vindo(a)!';

        return (
            <Section>
                <Header background="#5A78FF" padding logo>
                    <Container>
                        <Row>
                            <Col sm={12} md={{ span: 6, offset: 3 }}>
                                <h2>Parabéns, {firstName}</h2>
                                <figure className="loovi-check-icon">
                                    <img src={checkImage} alt="Parabéns" />
                                </figure>
                            </Col>
                        </Row>
                    </Container>
                </Header>
                <span className="loovi-spacer" />
                <Container>
                    <Row>
                        <Col sm={12} md={{ span: 6, offset: 3 }}>
                            <Body>
                                <div className="loovi-center">
                                    <p className="loovi-desc">Falta pouco para você ativar seu seguro!</p>
                                    <img src={progress}  alt='Progresso do Seguro Loovi' className='progress'/>
                                </div>
                            </Body>
                        </Col>
                    </Row>
                </Container>
            </Section>
        )
    }
}

const Section = styled.section`
    position: relative;

    .loovi-desc{
        font-family: 'BrownStd-Regular';
        font-size: 18px;
        color: #000 !important;
        letter-spacing: -0.64px;
        text-align: center;
        line-height: 21px;
        margin-bottom: 30px;
    }

    .progress{
        margin: 0 auto;
        width: 100%;
        max-width: 700px;
        height: auto;
        margin-top: 20px;
    }

    .loovi-check-icon img{

        width: 44px;
        height: 44px;
        margin-top: 20px;
    }

    .loovi-app-icon{

        margin-bottom: 20px;

        img{

           width: 255px;
           height: auto;
        }
    }
`

export default Success
