import { api_keyed, URLS } from '../helpers/Request';

export default class CorreiosService {
  static async getAddress(cep) {
    try {
      return await api_keyed.get(`${URLS.CORREIOS}/endereco/${cep}`).json();
    } catch (e) {
      throw e;
    }
  }

}
