import React from 'react';
import styled from 'styled-components';
import { Title } from '../stylesGlobal';
import { Container, Row, Col } from 'react-bootstrap';
import { MobileView } from "react-device-detect";
import Button from './Button'

import { NavBar } from './NavBar';

import heroImage from '../assets/images/hero-background.png';
import heroAppImage from '../assets/images/hero-app.png';
import faqWallpaper from '../assets/images/faq-wallpaper.jpg';

function contentResolver(context) {
    switch (context) {
        case 'faq':
            return (
                <HeroContainer context={context} style={{ marginBottom: '100px' }} background={faqWallpaper}>
                    <Container>
                        <Row className="d-flex align-items-center">
                            <HeroColumn sm={12} md={5} lg={5}>
                                <div >
                                    <h5 className="loovi-uppercase">Perguntas frequentes</h5>
                                    <Title style={{ fontSize: '54px' }} fontColor="#fff">Tem dúvida? <br /> A Loovi responde.</Title>
                                    <p style={{ width: '215px' }}>
                                        Separamos as principais dúvidas dos clientes aqui.
                                </p>
                                </div>

                            </HeroColumn>
                            <Col sm={12} md={7} lg={{ span: 6, offset: 1 }}>
                                <figure><img alt="" /></figure>
                            </Col>
                        </Row>
                    </Container>
                    {!isMobile() ? (
                        <>
                            <SmallBlueCircle />
                            <BottomGreenCircle />
                            <BigBlueCircle />
                            <WhiteCircle />
                        </>
                    ) : (
                            <>
                                <SmallBlueCircle />
                                <BottomGreenCircle />
                                <BigBlueCircle />
                            </>
                        )}

                    <Container>
                        <Row>
                            <Col sm={12} md={5}>
                                <HeroRetangle>
                                    <div className="loovi-scroll">Scroll</div>
                                </HeroRetangle>
                            </Col>
                        </Row>
                    </Container>

                </HeroContainer>
            );
        default:
            return (
                <HeroContainer background={heroImage}>
                    <Container >
                        <Row className="d-flex align-items-center">
                            <HeroColumn  sm={12} md={5} lg={5}>
                                <div className="loovi-body">
                                    <h5 className="loovi-uppercase">Garantia Loovi</h5>
                                    <Title fontColor="#fff">Achamos ou pagamos</Title>
                                    <p style={{textAlign: 'justify'}}>
                                        Localizamos o seu carro roubado mas, se por algum motivo não encontramos, pode ficar tranquilo. A Loovi te dá outro de volta.   ; - )
                                    </p>
                                    <MobileView>
                                        <Button href="/checkout">Contrate <span>Agora</span></Button>
                                    </MobileView>
                                    {/*                                     <Link className="loovi-read-more" primary href="https://google.com">Saiba mais <FontAwesomeIcon icon="chevron-right" /></Link> */}
                                </div>
                                <HeroCircleOne></HeroCircleOne>
                                <HeroCircleTwo></HeroCircleTwo>
                            </HeroColumn>
                            <Col sm={12} md={7} lg={{ span: 6, offset: 1 }}>
                                <figure><img src={heroAppImage} alt="" /></figure>
                                <HeroBigCircle></HeroBigCircle>
                                <HeroCircleThree></HeroCircleThree>
                                <HeroCircleFour></HeroCircleFour>
                                <HeroCircleFive></HeroCircleFive>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col sm={12} md={5}>
                                <HeroRetangle>
                                    <div className="loovi-scroll">Scroll</div>
                                </HeroRetangle>
                            </Col>
                        </Row>
                    </Container>
                </HeroContainer>
            );
    }
}

export const Hero = ({ heroContext }) => (
    <React.Fragment>
        <NavBar />
        {contentResolver(heroContext)}
    </React.Fragment>
)

const HeroColumn = styled(Col)`
    @media all and (orientation: landscape) {
        @media (max-width: 768px) {
            top: 120px;
        }
    }
`;
const HeroContainer = styled.div`
    min-height: 800px;
    background-image: url(${props => props.background});
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    overflow: hidden;
    position: relative;

    @media all and (orientation: landscape) {
        @media (max-width: 768px){
            min-height: 480px;
            height: 480px;
        }
    }

    p{
        font-size: 18px;
        margin: 30px 0;
    }
    
    figure{
        min-height: 800px;
        position: relative;
        margin: 0;



        img{
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
        }
    }

    .loovi-body{
        position: relative;
        z-index: 1;
    }

    @media (min-width: 320px) and (max-width: 480px){

        min-height: auto;
        height: ${props => props.context === 'faq' ? '625px' : '814px'} ;
        padding-top: 150px;
        background-position: center;

        figure{

            min-height: auto;

            img{

                position: relative;
                width: 464px;
                max-width: initial;
                height: auto;
                margin-left: -70px;
            }
        }

        .loovi-body{

            text-align: center;
            margin: 0 15px;
        }

        .loovi-read-more{

            display: none;
        }
    }

    @media (min-width: 768px) and (max-width: 1024px){

        min-height: 660px;

        figure{ 
            
            min-height: 660px;

            img{

                width: auto;
            }
        }
    }
`

const SmallBlueCircle = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #5A78FF;
    position: absolute;
    z-index: 0;
    bottom: 530px;
    right: 140px;

    @media (max-width: 768px){
         top: -190px;
         left: 50%;
         bottom: auto;
         margin-left: -381px;
    }
`;

const BottomGreenCircle = styled.div`
    bottom: -80px;
    right: -70px;
    width: 247px;
    height: 247px;
    border-radius: 50%;
    border: 43px solid #57D9A3;
    position: absolute;
    z-index: 1;

    @media (max-width: 768px){
        margin-left: -381px;
        bottom: -100px;
    }
`;

const BigBlueCircle = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #5A78FF;
    position: absolute;
    z-index: 0;
    bottom: 25px;
    left: -100px;


    @media (max-width: 768px){
        bottom: -110px;
        left: -140px;
    }
`;

const WhiteCircle = styled.div`
width: 125px;
    height: 125px;
    bottom: 400px;
    left: -79px;
    border-radius: 50%;
    border: 22px solid #ffffff;
    position: absolute;
    z-index: 1;

    @media (max-width: 768px){
        top: 170px;
        left: -95px;
    }
`;

const HeroBigCircle = styled.div`
    width: 763px;
    height: 763px;
    border-radius: 50%;
    background-color: #5A78FF;
    position: absolute;
    z-index: 0;
    bottom: -150px;
    left: 30px;

    @media (max-width: 768px){
         top: -190px;
         left: 50%;
         bottom: auto;
         margin-left: -381px;
    }
`;

const HeroRetangle = styled.div`
    background: #FFFFFF;
    width:2000px;
    height: 120px;
    position: absolute;
    bottom: 0;
    right: -60px;

    .loovi-scroll{
        position: absolute;
        transform: rotate(-90deg);
        font-size: 14px;
        right: -100px;
        bottom: 42px;

        &::before{
            content: '';
            border: solid 1px #fff;
            width: 90px;
            display: inline-block;
            vertical-align: middle;
            margin-top: -1px;
            margin-right: 5px;
        }
    }

    @media (max-width: 768px){
        
        display: none;
    }
`;

const HeroCircleOne = styled.div`
    width: 213px;
    height: 213px;
    border-radius: 50%;
    border: 43px solid #57D9A3;
    position: absolute;
    z-index: 1;
    top: -70px;
    left: -270px;

    @media (max-width: 768px){
        top: auto;
        bottom: -220px;
        left: -120px;
    }
`;

const HeroCircleTwo = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #57D9A3;
    position: absolute;
    z-index: 1;
    top: 280px;
    left: -100px;

    @media (max-width: 768px){
        display: none;
    }
`;

const HeroCircleThree = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #57D9A3;
    position: absolute;
    z-index: 1;
    top: 245px;
    right: -100px;

    @media (max-width: 768px){
        width: 56px;
        height: 56px;
        top: -180px;
        right: -30px;
        z-index: 0;
    }

    @media all and (orientation: landscape) {
        display: none;
    }
`;

const HeroCircleFour = styled.div`
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 29px solid #FFFFFF;
    position: absolute;
    z-index: 1;
    top: 540px;
    right: -200px;
`;

const HeroCircleFive = styled.div`
    width: 106px;
    height: 106px;
    border-radius: 50%;
    background: #57D9A3;
    position: absolute;
    z-index: 1;
    bottom: -50px;
    right: 0;

    @media (max-width: 768px){
        top: auto;
        bottom: 350px;
        left: 170px; 
    } 
`;

function isMobile() {
    return window.innerWidth < 768;
}