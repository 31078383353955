import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const SocialIcons = () => ( 
    <Section>
       <a href="https://www.facebook.com/loovibrasil/">
            <span className="d-flex align-items-center justify-content-center"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></span>
        </a>
        <a href="https://www.youtube.com/channel/UC-ZKWlQ2dxCrrjtY69yXmgQ">
            <span className="d-flex align-items-center justify-content-center"><FontAwesomeIcon icon={['fab', 'youtube']} /></span>
        </a>
        <a href="https://twitter.com/loovibrasil">
            <span className="d-flex align-items-center justify-content-center"><FontAwesomeIcon icon={['fab', 'twitter']} /></span>
        </a>
        <a href="https://www.instagram.com/loovibrasil/">
            <span className="d-flex align-items-center justify-content-center"><FontAwesomeIcon icon={['fab', 'instagram']} /></span>
        </a>
    </Section>
)

const Section = styled.div`
    a{

        color: #393043;
        display: inline-block;
        background: #FFFFFF;
        width: 35px;
        height: 35px;
        text-align: center;
        margin-right: 15px;
        border-radius: 50%;
        transition: .4s;

        span{

            width: 35px;
            height: 35px;   
            color: #393043;
        }

        &:hover{

            background: #57D9A3;
        }
    }
`