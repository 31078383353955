import React from 'react'
import styled from 'styled-components'
import googlePlayImage from '../assets/images/google-play.png';
import appleImage from '../assets/images/apple.png';

export const StoreIcons = () => (

    <Icons>
        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.looviapp&hl=pt_BR">
            <figure className="d-flex align-items-center justify-content-center"><img src={googlePlayImage} alt="Google Play" /></figure>
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/br/app/loovi/id1275069424">
            <figure className="d-flex align-items-center justify-content-center"><img src={appleImage} alt="Apple" /></figure>
        </a>
    </Icons>
)

const Icons = styled.div`
    position: relative;

    a{
        background: #FFFFFF;
        display: inline-block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        text-align: center;
        margin-right: 20px;
        transition: .4s;

        figure{

            width: 60px;
            height: 60px;
        }

        img{

            max-width: 28px;
            height: auto;
        }

        &:hover{

            opacity: .6;
        }
    }
`