// Dependencias

import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
import { createBrowserHistory } from 'history'


// Fontawesome

import { library } from '@fortawesome/fontawesome-svg-core'; 
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faChevronRight, faLock } from '@fortawesome/free-solid-svg-icons';

// Componentes

import { FAQ } from './FAQ';
import { VoucherIndication } from './VoucherIndication';
import { Checkout } from './Checkout';
import { CheckoutPayment } from './CheckoutPayment';
import { PrivacyPolicy } from './components/checkout/PrivacyPolicy'
import { TermsOfUse } from './components/checkout/TermsOfUse'
import Hire from './Hire';
import HireWithoutCampaign from './HireWithoutCampaign';

library.add(faCheckSquare, faCoffee, faChevronRight, faLock, fab)

const history = createBrowserHistory();

function App() {
  return (
    <React.Fragment>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Checkout} />
          <Route exact path="/payment/:quotId" component={CheckoutPayment} />
          <Route exact path="/:step" component={Checkout} />
          <Route path="/contratar/:sapPlanId/:sapDiscountId" component={Hire} />
          <Route path="/contratar/:sapPlanId" component={HireWithoutCampaign} />

          <Route exact path="/politica-de-privacidade" component={PrivacyPolicy} />
          <Route exact path="/termos-de-uso" component={TermsOfUse} />
          <Route exact path="/duvidas-frequentes" component={FAQ} />
          <Route exact path="/presente" component={VoucherIndication} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
