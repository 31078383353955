// Dependencias

import React from 'react'
import styled from 'styled-components'
import Lockr from 'lockr'

// Componentes

import ContactData from './components/checkout/ContactData'
import FormCPF from './components/checkout/FormCPF'
import FormPayment from './components/checkout/FormPayment'
import FormLicencePlate from './components/checkout/FormLicencePlate'
import Success from './components/checkout/Success'
import Footer from './components/checkout/Footer'
import { authSAP } from './helpers/Sap'

const stepsRoutes = [
    '',
    'contact',
    'address',
    'payment',
    'licence',
    'sucess'
]

export class Checkout extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            step: 1,
            customerName: '',
            customerEmail: '',
            customerPhoneNumber: '',
            customerCPF: '',
            customerCNPJ: '', //34.504.257/0001-00
            customerCEP: '',
            customerStreet: '',
            customerStreetNum: '',
            customerAdressComplement: '',
            customerNeighborhood: '',
            customerUF: {},
            customerCity: {},
            customerShippingCEP: '',
            customerShippingStreet: '',
            customerShippingStreetNum: '',
            customerShippingAdressComplement: '',
            customerShippingNeighborhood: '',
            customerShippingUF: {},
            customerShippingCity: {},
            customerChangeShipping: false,
            customerQty: 1,
            customerCoupom: '',
            customerCardNumber: '',
            customerCardName: '',
            customerCardExpiration: '',
            customerCardCV: '',
            customerTermAgree: '',
            customerFrete: 'PAC',
            customerFreteValor: '8.00',
            customerTipoVeiculo: 'carro',
            customerLicencePlate: ''
        }
    }

    // Ir para o próximo passo

    nextStep = () => {
        this.goToStep(this.state.step + 1)
    }

    // Ir para o passo anterior

    prevStep = () => {
        this.goToStep(this.state.step - 1)
    }

    goToStep = (step) => {
        this.setState({
            step
        })

        this.props.history.push(`/${stepsRoutes[step]}/`);
        setTimeout(() => {
            window.scrollTo(0, 1);
        }, 60);
    }

    // Procedimentos ligados a alteração de valores dos campos

    handleChange = event => {
        if (event.target) {
            const target = event.target
            const name = target.name
            const value = target.type === 'checkbox' ? target.checked : target.value;
            this.setState({ [name]: value })
            // this.updateInput(name, value)
        } else {
            this.setState({ [event.name]: event.value })
            // this.updateInput(event.name, event.value)
        }
    }

    updateInput = (name, value) => {
        const inputChanged = document.querySelector(`input[name=${name}]`)
        if (inputChanged && inputChanged.type === 'text') {
            inputChanged.value = value;
        }
    }

    handleSelectChange = (value, name) => {


        this.setState(
            { [name]: value }
        );
    };

    componentDidMount() {
        authSAP();

        this.props.history.listen((_, action) => {
            if (action === "POP") {
                this.setState({
                    step: this.state.step - 1
                })
            }
        })

        this.setState({
            ...this.state,
            ...Lockr.get('loovi-metrics'),
            ...Lockr.get('Loovi-SAP')
        })

        console.log("Lockr.get(Loovi-SAP) :",  Lockr.get('Loovi-SAP'));

        const HireData = Lockr.get('HireData');

        if (HireData?.alreadyUsed) {
          Lockr.rm('HireData');
        } else {
          Lockr.set('HireData', {
            ...HireData,
            alreadyUsed: true,
          });
        }
    }

    render() {

        const {
            customerName,
            customerEmail,
            customerPhoneNumber,
            customerCPF,
            customerCNPJ,
            customerCEP,
            customerStreet,
            customerStreetNum,
            customerAdressComplement,
            customerNeighborhood,
            customerUF,
            customerCity,
            customerChangeShipping,
            customerShippingCEP,
            customerShippingStreet,
            customerShippingStreetNum,
            customerShippingAdressComplement,
            customerShippingNeighborhood,
            customerShippingUF,
            customerShippingCity,
            customerQty,
            customerCoupom,
            customerCardNumber,
            customerCardName,
            customerCardExpiration,
            customerCardCV,
            customerTermAgree,
            customerFrete,
            customerFreteValor,
            customerTipoVeiculo,
            customerLicencePlate,
            ie
        } = this.state

        const values = {
            customerName,
            customerEmail,
            customerPhoneNumber,
            customerCPF,
            customerCNPJ,
            customerCEP,
            customerStreet,
            customerStreetNum,
            customerAdressComplement,
            customerNeighborhood,
            customerUF,
            customerCity,
            customerChangeShipping,
            customerShippingCEP,
            customerShippingStreet,
            customerShippingStreetNum,
            customerShippingAdressComplement,
            customerShippingNeighborhood,
            customerShippingUF,
            customerShippingCity,
            customerQty,
            customerCoupom,
            customerCardNumber,
            customerCardName,
            customerCardExpiration,
            customerCardCV,
            customerTermAgree,
            customerFrete,
            customerFreteValor,
            customerTipoVeiculo,
            customerLicencePlate,
            ie
        }

        switch (stepsRoutes.indexOf(this.props.match.params.step)) {
            case 1:
                return (
                    <React.Fragment>
                        <Section>
                            <ContactData
                                nextStep={this.nextStep}
                                handleChange={this.handleChange}
                                values={values}
                            />
                        </Section>
                        <Footer />
                    </React.Fragment>
                )
            case 2:
                return (
                    <React.Fragment>
                        <Section>
                            <FormCPF
                                nextStep={this.nextStep}
                                prevStep={this.prevStep}
                                handleChange={this.handleChange}
                                handleSelectChange={this.handleSelectChange}
                                values={values} />
                        </Section>
                        <Footer />
                    </React.Fragment>
                )
            // case 3:
            //     return(
            //         <React.Fragment>
            //             <Section>
            //                 <Address
            //                     nextStep = {this.nextStep}
            //                     prevStep = {this.prevStep}
            //                     handleChange = {this.handleChange}
            //                     handleSelectChange = {this.handleSelectChange}
            //                     values={values} />
            //             </Section>
            //             <Footer />
            //         </React.Fragment>
            //     )
            // case 3:
            //     return(
            //         <React.Fragment>
            //             <Section>
            //                 <ShippingAddress
            //                     nextStep = {this.nextStep}
            //                     prevStep = {this.prevStep}
            //                     values={values} />
            //             </Section>
            //             <Footer />
            //         </React.Fragment>
            //     )
            case 3:
                return (
                    <React.Fragment>
                        <Section>
                            <FormPayment
                                nextStep={this.nextStep}
                                prevStep={this.prevStep}
                                handleChange={this.handleChange}
                                goToStep={this.goToStep}
                                handleSelectChange={this.handleSelectChange}
                                values={values} />
                        </Section>
                        <Footer />
                    </React.Fragment>
                )
            case 4:
                return (
                    <React.Fragment>
                        <Section>
                            <FormLicencePlate
                                nextStep={this.nextStep}
                                handleChange={this.handleChange} />
                        </Section>
                        <Footer />
                    </React.Fragment>
                )
            case 5:
                return (
                    <React.Fragment>
                        <Section>
                            <Success values={values} />
                        </Section>
                        <Footer />
                    </React.Fragment>
                )
            default:
                return (
                    <React.Fragment>
                        <Section>
                            <ContactData
                                nextStep={this.nextStep}
                                handleChange={this.handleChange}
                                values={values}
                            />
                        </Section>
                        <Footer />
                    </React.Fragment>
                )
        }
    }
}

const Section = styled.section`
    position: relative;
    min-height: 100%;
`
