// Dependencias

import React from "react";
import styled from "styled-components";
import Lockr from "lockr";

// Componentes

import { getCotation } from "./components/painel/services";
import LoaderAnimation from "./components/LoaderAnimation";
import FormPayment from "./components/checkout/FormPayment";
import FormLicencePlate from "./components/checkout/FormLicencePlate";
import Success from "./components/checkout/Success";
import Footer from "./components/checkout/Footer";
import { MobileView, BrowserView } from "react-device-detect";
import { Container, Row } from "react-bootstrap";

const stepsRoutes = {
  payment: "payment",
  licence: "licence",
  success: "success",
  feedback: "feedback",
};

export class CheckoutPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      customerName: "",
      customerEmail: "",
      customerPhoneNumber: "",
      customerCPF: "",
      customerCNPJ: "", //34.504.257/0001-00
      customerCEP: "",
      customerStreet: "",
      customerStreetNum: "",
      customerAdressComplement: "",
      customerNeighborhood: "",
      customerUF: {},
      customerCity: {},
      customerShippingCEP: "",
      customerShippingStreet: "",
      customerShippingStreetNum: "",
      customerShippingAdressComplement: "",
      customerShippingNeighborhood: "",
      customerShippingUF: {},
      customerShippingCity: {},
      customerChangeShipping: false,
      customerQty: 1,
      customerCoupom: "",
      customerCardNumber: "",
      customerCardName: "",
      customerCardExpiration: "",
      customerCardCV: "",
      customerTermAgree: "",
      customerFrete: "PAC",
      customerFreteValor: "8.00",
      customerTipoVeiculo: "carro",
      customerLicencePlate: "",
      isLoading: true,
      stepsRouteActived: stepsRoutes.payment,
    };
  }

  goToLincence = () => {
    this.setState({ stepsRouteActived: stepsRoutes.licence });
  };

  goToSuccess = () => {
    this.setState({ stepsRouteActived: stepsRoutes.success });
  };

  handleChange = (event) => {
    if (event.target) {
      const target = event.target;
      const name = target.name;
      const value = target.type === "checkbox" ? target.checked : target.value;
      this.setState({ [name]: value });
      this.updateInput(name, value);
    } else {
      this.setState({ [event.name]: event.value });
      this.updateInput(event.name, event.value);
    }
  };

  updateInput = (name, value) => {
    const inputChanged = document.querySelector(`input[name=${name}]`);
    if (inputChanged && inputChanged.type === "text") {
      inputChanged.value = value;
    }
  };

  mountLooviMetrics = (data) => {
    return {
      customerName: data.quotation.quotationFormBusinessPartner.cardName,
      customerEmail: data.quotation.quotationFormBusinessPartner.email,
      customerPhoneNumber: data.quotation.quotationFormBusinessPartner.phone1,
      customerCEP: data.quotation.quotationForm.zipCode,
      customerCityCheck: data.quotation.quotationFormAddressPayment.city,
      customerNeighborhood: data.quotation.quotationFormAddressPayment.block,
      customerStreet: data.quotation.quotationFormAddressPayment.street,
      customerStreetNum: data.quotation.quotationFormAddressPayment.streetNo,
      customerUFCheck: data.quotation.quotationFormAddressPayment.state,
      customerAdressComplement: "",
      customerIbgeCode: data.quotation.quotationFormAddressPayment.ibgeCode,
      customerCNPJ:
        data.quotation.quotationFormBusinessPartner.cpf ||
        data.quotation.quotationFormBusinessPartner.cnpj,
      hasAddressShipping: false,
      hasIe: !!data.quotation.quotationFormBusinessPartner.ie,
      customerShippingCEP: data.quotation.quotationFormAddressDelivery.zipCode,
      customerShippingStreet:
        data.quotation.quotationFormAddressDelivery.street,
      customerShippingStreetNum:
        data.quotation.quotationFormAddressDelivery.streetNo,
      customerShippingAdressComplement:
        data.quotation.quotationFormAddressDelivery.building,
      customerShippingNeighborhood:
        data.quotation.quotationFormAddressDelivery.block,
      customerShippingUF: {
        value: data.quotation.quotationFormAddressDelivery.state,
      },
      customerShippingCity: {
        value: data.quotation.quotationFormAddressDelivery.city,
      },
    };
  };

  async componentDidMount() {
    try {
      const { quotId } = this.props.match.params;
      const response = await getCotation(quotId);

      if (!response.sucess) {
        alert("Ops, parece que ocorreu um erro #0001");
        this.setState({
          isLoading: false,
          stepsRouteActived: stepsRoutes.feedback,
        });
      } else if (
        response.sucess &&
        response.quotation.quotationForm.quotID !== quotId
      ) {
        alert("Ops, parece que ocorreu um erro. #0002");
        this.setState({
          isLoading: false,
          stepsRouteActived: stepsRoutes.feedback,
        });
      } else if (
        response.sucess &&
        response.quotation.quotationForm.quotID === quotId &&
        response.quotation.quotationForm.status !== "VA" &&
        response.quotation.quotationForm.status !== "TT"
      ) {
        alert("Esta venda já foi finalizada!");
        window.close();
      } else {
        Lockr.set("Loovi-SAP", response);

        const looviMetrics = this.mountLooviMetrics(response);
        Lockr.set("loovi-metrics", looviMetrics);
        this.setState({ ...looviMetrics, isLoading: false });
      }
    } catch (err) {
      //window.location
      console.error(err);
      alert("Ops, parece que ocorreu um erro. #0003");
    }
  }

  render() {
    const {
      customerName,
      customerEmail,
      customerPhoneNumber,
      customerCPF,
      customerCNPJ,
      customerCEP,
      customerStreet,
      customerStreetNum,
      customerAdressComplement,
      customerNeighborhood,
      customerUF,
      customerCity,
      customerChangeShipping,
      customerShippingCEP,
      customerShippingStreet,
      customerShippingStreetNum,
      customerShippingAdressComplement,
      customerShippingNeighborhood,
      customerShippingUF,
      customerShippingCity,
      customerQty,
      customerCoupom,
      customerCardNumber,
      customerCardName,
      customerCardExpiration,
      customerCardCV,
      customerTermAgree,
      customerFrete,
      customerFreteValor,
      customerTipoVeiculo,
      customerLicencePlate,
      ie,
      isLoading,
      stepsRouteActived,
    } = this.state;

    const values = {
      customerName,
      customerEmail,
      customerPhoneNumber,
      customerCPF,
      customerCNPJ,
      customerCEP,
      customerStreet,
      customerStreetNum,
      customerAdressComplement,
      customerNeighborhood,
      customerUF,
      customerCity,
      customerChangeShipping,
      customerShippingCEP,
      customerShippingStreet,
      customerShippingStreetNum,
      customerShippingAdressComplement,
      customerShippingNeighborhood,
      customerShippingUF,
      customerShippingCity,
      customerQty,
      customerCoupom,
      customerCardNumber,
      customerCardName,
      customerCardExpiration,
      customerCardCV,
      customerTermAgree,
      customerFrete,
      customerFreteValor,
      customerTipoVeiculo,
      customerLicencePlate,
      ie,
    };

    if (isLoading) return <LoaderAnimation show={isLoading} />;

    switch (stepsRouteActived) {
      default:
        return (
          <React.Fragment>
            <Section>
              <FormPayment
                goToStep={this.goToStep}
                handleChange={this.handleChange}
                isContation
                nextStep={this.goToLincence}
                values={values}
              />
            </Section>
            <Footer />
          </React.Fragment>
        );

      case stepsRoutes.licence:
        return (
          <React.Fragment>
            <Section>
              <FormLicencePlate
                handleChange={this.handleChange}
                nextStep={this.goToSuccess}
              />
            </Section>
            <Footer />
          </React.Fragment>
        );

      case stepsRoutes.success:
        return (
          <React.Fragment>
            <Section>
              <Success values={values} />
            </Section>
            <Footer />
          </React.Fragment>
        );
      case stepsRoutes.feedback:
        return (
          <React.Fragment>
            <Section>
              <MobileView>
                <div className="loovi-sub-header loovi-center">
                  <Container>
                    <h3>Ops!</h3>
                    <h4>Ocorreu um erro.</h4>
                  </Container>
                </div>
              </MobileView>
              <BrowserView>
                <Container fluid>
                  <Row>
                    <div>
                      <div>
                        <h3>Ops!</h3>
                        <h4>Ocorreu um erro.</h4>
                      </div>
                    </div>
                  </Row>
                </Container>
              </BrowserView>
            </Section>
          </React.Fragment>
        );
    }
  }
}

const Section = styled.section`
  position: relative;
  min-height: 100%;
`;
