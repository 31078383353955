// Dependencias

import React from 'react'
import styled from 'styled-components'

// Componentes

import { Container, Row, Col} from 'react-bootstrap'
import Header from '../Header'
import Body from '../Body'
import Footer from '../checkout/Footer'

export const PrivacyPolicy = () =>{

    return(
        <React.Fragment>
            <Section>
                <Header background="#5A78FF" padding logo />
                <Container>
                    <Row>
                        <Col sm={12} md={{span: 6, offset:3}}>
                            <Body>
                                <h2>Política de Privacidade</h2>
                                <div className="loovi-content loovi-color-5">
                                    <h5>1) Lorem ipsum dolor sit amet, conse  der ctetur adipiscing elit. Nunc sit </h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit amet aliquam mauris. In consectetur accumsan semper. Maecenas in semper sem. Duis vestibulum lacinia turpis, cursus egestas massa rhoncus scelerisque. Quisque interdum pulvinar ex, sed scelerisque libero fermentum et. Aliquam efficitur in erat sit amet pharetra. Nullam vitae tortor lobortis orci pulvinar ornare. Duis a nisl sed lorem accumsan tempus. Nulla sit amet urna nec turpis sagittis posuere. Nullam purus orci, convallis nec interdum eget, consequat sed urna.</p>

                                    <h5>2) Lorem ipsum dolor sit amet, conse  der ctetur adipiscing elit. Nunc sit </h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit amet aliquam mauris. In consectetur accumsan semper. Maecenas in semper sem. Duis vestibulum lacinia turpis, cursus egestas massa rhoncus scelerisque. Quisque interdum pulvinar ex, sed scelerisque libero fermentum et. Aliquam efficitur in erat sit amet pharetra. Nullam vitae tortor lobortis orci pulvinar ornare. Duis a nisl sed lorem accumsan tempus. Nulla sit amet urna nec turpis sagittis posuere. Nullam purus orci, convallis nec interdum eget, consequat sed urna.</p>

                                    <h5>3) Lorem ipsum dolor sit amet, conse  der ctetur adipiscing elit. Nunc sit </h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sit amet aliquam mauris. In consectetur accumsan semper. Maecenas in semper sem. Duis vestibulum lacinia turpis, cursus egestas massa rhoncus scelerisque. Quisque interdum pulvinar ex, sed scelerisque libero fermentum et. Aliquam efficitur in erat sit amet pharetra. Nullam vitae tortor lobortis orci pulvinar ornare. Duis a nisl sed lorem accumsan tempus. Nulla sit amet urna nec turpis sagittis posuere. Nullam purus orci, convallis nec interdum eget, consequat sed urna.</p>
                                </div>
                            </Body>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <Footer />
        </React.Fragment>
    )
}

const Section = styled.section`
    position: relative;

    h2{

        font-size: 24px;
        color: #5A78FF;
        letter-spacing: -0.86px;
        text-align: center;
        line-height: 30px;
        text-align: center;
    }

    h5{

        font-size: 15px;
        color: #899299;
        letter-spacing: -0.32px;
        line-height: 21px;
        text-transform: uppercase;
    }
`